import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const SEOHelmet = ({ 
  title, 
  description, 
  keywords,
  image = '/llamacloud-cover.png',
  type = 'website',
  canonical,
  structuredData
}) => {
  const location = useLocation();
  const currentUrl = `https://brilliantai.co${location.pathname}`;
  const pageTitle = title ? `${title} | Brilliant AI` : 'Brilliant AI | The dev platform for all your Gen AI needs';
  const pageDescription = description || 'Brilliant AI - The dev platform for all your Gen AI needs. Access top-performing language models, image generation, video creation, and more.';
  const pageKeywords = keywords || 'AI, artificial intelligence, LLM, language models, API, image generation, video generation, developers';
  const pageCanonical = canonical || currentUrl;

  // Default structured data for organization
  const defaultStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Brilliant AI Technologies LTD",
    "url": "https://brilliantai.co",
    "logo": "https://brilliantai.co/Brilliant AI Logo 2.png",
    "sameAs": [
      "https://x.com/brilliantai_hq",
      "https://www.linkedin.com/company/brilliantai-hq/",
      "https://www.facebook.com/profile.php?id=61564797920098"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "hello@brilliantai.co",
      "contactType": "customer service"
    },
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "5 Brayford Square",
      "addressLocality": "London",
      "addressRegion": "England",
      "postalCode": "E1 0SG",
      "addressCountry": "UK"
    }
  };

  // Combine with any additional structured data passed as prop
  const jsonLd = structuredData ? 
    [defaultStructuredData, structuredData] : 
    defaultStructuredData;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={pageKeywords} />
      <link rel="canonical" href={pageCanonical} />

      {/* Open Graph */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="Brilliant AI" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={image} />

      {/* Structured Data (JSON-LD) */}
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
    </Helmet>
  );
};

export default SEOHelmet;