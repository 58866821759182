// /Users/johnolafenwa/source/llama-cloud/frontend/src/services/api.js

import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

console.log(BACKEND_URL);

const api = axios.create({
  baseURL: BACKEND_URL,
});

// Request interceptor to add the JWT token to headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Improved streaming method with better error handling
api.stream = async (url, data) => {
  try {
    const token = localStorage.getItem('authToken');
    const response = await fetch(`${BACKEND_URL}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      // Try to get more detailed error information
      const errorText = await response.text();
      throw new Error(errorText || `HTTP error ${response.status}`);
    }

    return response.body.getReader();
  } catch (error) {
    console.error('API Stream Error:', error);
    throw error;
  }
};

export default api;