// frontend/src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import SignupSuccess from './pages/SignupSuccess';
import Auth from './pages/Auth';
import Playground from './pages/Playground';
import ContentStudio from './pages/ContentStudio';
import Billing from './pages/Billing';
import Profile from './pages/Profile';
import Welcome from './pages/Welcome';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import Dashboard from './pages/Dashboard';
import AdminDashboard from './pages/AdminDashboard';
import SEOHelmet from './components/SEOHelmet';
//import StudioAnnouncement from './components/StudioAnnouncement'; // Import the announcement component

// Main App Component
function App() {
  const theme = useTheme();
  const [showStudioAnnouncement, setShowStudioAnnouncement] = useState(false);
  
  // Function to close the announcement
  const handleCloseAnnouncement = () => {
    setShowStudioAnnouncement(false);
  };

  return (
    <AuthProvider>
      <Router>
        <AppContent 
          showStudioAnnouncement={showStudioAnnouncement}
          setShowStudioAnnouncement={setShowStudioAnnouncement}
          handleCloseAnnouncement={handleCloseAnnouncement}
        />
      </Router>
    </AuthProvider>
  );
}

// Separate component to access router hooks
function AppContent({ showStudioAnnouncement, setShowStudioAnnouncement, handleCloseAnnouncement }) {
  const location = useLocation();
  
  // Show announcement whenever we're on the home page
  useEffect(() => {
    // Show announcement when path is exactly '/'
    if (location.pathname === '/') {
      setShowStudioAnnouncement(true);
    } else {
      setShowStudioAnnouncement(false);
    }
  }, [location.pathname, setShowStudioAnnouncement]);

  return (
    <>
      {/* Default SEO for all pages not using specific SEO settings */}
      <SEOHelmet />
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          minHeight: '100vh',
          width: '100%',
          maxWidth: '100vw',
          overflowX: 'hidden'
        }}
      >
        <Navbar />
        <Box
          sx={{
            flex: 1,
            width: '100%',
            maxWidth: '100vw',
            overflowX: 'hidden',
            p: { xs: 1, sm: 2 }, // Responsive padding
          }}
        >
          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <PublicRoute>
                  <Signup />
                </PublicRoute>
              }
            />
            <Route
              path="/signup-success"
              element={
                <PublicRoute>
                  <SignupSuccess />
                </PublicRoute>
              }
            />
            <Route path="/auth" element={<Auth />} />
            <Route
              path="/welcome"
              element={
                <ProtectedRoute>
                  <Welcome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <PublicRoute>
                  <Home />
                </PublicRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/playground"
              element={
                <ProtectedRoute>
                  <Playground />
                </ProtectedRoute>
              }
            />
            {/* Content Studio */}
            <Route
              path="/studio"
              element={
                <ProtectedRoute>
                  <ContentStudio />
                </ProtectedRoute>
              }
            />
            <Route
              path="/billing"
              element={
                <ProtectedRoute>
                  <Billing />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
      </Box>
      
      {/* Studio Announcement Overlay */}
      {/*{showStudioAnnouncement && (*/}
      {/*  <StudioAnnouncement onClose={handleCloseAnnouncement} />*/}
      {/*})}*/}
    </>
  );
}

export default App;