// frontend/src/components/AnimatedBackground.js

import React, { useRef, useEffect, useState } from 'react';
import { Box, styled, useTheme, useMediaQuery } from '@mui/material';

const CanvasContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 0,
});

const AnimatedBackground = ({ density = 'medium' }) => {
  const canvasRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Determine particle count based on screen size and density prop
  const getParticleCount = () => {
    const densityMap = {
      low: { base: 25, mobile: 15, tablet: 20 },
      medium: { base: 40, mobile: 25, tablet: 30 },
      high: { base: 60, mobile: 35, tablet: 45 },
    };

    const counts = densityMap[density] || densityMap.medium;
    return isMobile ? counts.mobile : isTablet ? counts.tablet : counts.base;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions
    const resizeCanvas = () => {
      const { offsetWidth, offsetHeight } = canvas.parentElement;
      const pixelRatio = window.devicePixelRatio || 1;

      setDimensions({
        width: offsetWidth,
        height: offsetHeight,
      });

      canvas.width = offsetWidth * pixelRatio;
      canvas.height = offsetHeight * pixelRatio;
      ctx.scale(pixelRatio, pixelRatio);

      canvas.style.width = `${offsetWidth}px`;
      canvas.style.height = `${offsetHeight}px`;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    // Particle class for collision animation
    class Particle {
      constructor(x, y) {
        this.init(x, y);
      }

      init(x, y) {
        // Position
        this.x = x !== undefined ? x : Math.random() * dimensions.width;
        this.y = y !== undefined ? y : Math.random() * dimensions.height;
        
        // Size
        const baseSize = isMobile ? 2 : isTablet ? 3 : 4;
        this.size = Math.random() * baseSize + 1;
        this.originalSize = this.size;
        
        // Movement
        const speed = isMobile ? 0.5 : isTablet ? 0.75 : 1;
        this.vx = (Math.random() - 0.5) * speed;
        this.vy = (Math.random() - 0.5) * speed;
        
        // Appearance
        const hue = Math.random() * 30 + (Math.random() > 0.7 ? 190 : 220); // Mainly blue hues with some variation
        this.color = `hsl(${hue}, 80%, 65%)`;
        this.alpha = Math.random() * 0.5 + 0.5;
        
        // Connection line properties
        this.connected = [];
        this.connectionRadius = isMobile ? 100 : isTablet ? 150 : 200;
        
        // Interactive behaviors
        this.collisionState = 0; // 0: normal, 1: colliding, 2: reversing
        this.collisionTime = 0;
        this.collisionDuration = 60; // frames
        this.reverseTime = 0;
        
        // Glow effect
        this.glowSize = this.size * 3;
        this.glowIntensity = 0.5;
        
        // Pulse effect
        this.pulse = 0;
        this.pulseSpeed = Math.random() * 0.02 + 0.01;
      }

      update(particles) {
        // Update position with boundary checking
        this.x += this.vx;
        this.y += this.vy;
        
        // Wrap around screen edges with a slight buffer
        if (this.x < -50) this.x = dimensions.width + 50;
        if (this.x > dimensions.width + 50) this.x = -50;
        if (this.y < -50) this.y = dimensions.height + 50;
        if (this.y > dimensions.height + 50) this.y = -50;
        
        // Update pulse effect
        this.pulse = (this.pulse + this.pulseSpeed) % (Math.PI * 2);
        
        // Find connections
        this.connected = [];
        particles.forEach(particle => {
          if (particle !== this) {
            const dx = this.x - particle.x;
            const dy = this.y - particle.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            
            // Check for collisions
            if (distance < this.size + particle.size) {
              // Start collision state if not already colliding
              if (this.collisionState === 0) {
                this.collisionState = 1;
                this.collisionTime = 0;
                
                // Collision response: reverse velocity with some randomization
                this.vx = -this.vx * (0.8 + Math.random() * 0.4);
                this.vy = -this.vy * (0.8 + Math.random() * 0.4);
                
                // Add a bit of randomness to avoid particles getting stuck together
                this.vx += (Math.random() - 0.5) * 0.3;
                this.vy += (Math.random() - 0.5) * 0.3;
              }
            }
            
            // Store connection if within radius
            if (distance < this.connectionRadius) {
              this.connected.push({
                particle,
                distance,
                opacity: 1 - (distance / this.connectionRadius)
              });
            }
          }
        });
        
        // Update collision state
        if (this.collisionState === 1) {
          this.collisionTime++;
          
          // Enhanced size during collision
          this.size = this.originalSize * (1 + Math.sin(this.collisionTime / 10) * 0.5);
          
          // Transition to reverse state
          if (this.collisionTime >= this.collisionDuration) {
            this.collisionState = 2; // start reversing
            this.reverseTime = 0;
          }
        } else if (this.collisionState === 2) {
          // Reverse animation
          this.reverseTime++;
          
          // Apply reverse effect (shrinking and fading)
          const progress = this.reverseTime / 30;
          this.size = this.originalSize * Math.max(0.5, 1 - progress * 0.5);
          
          // End of reverse animation
          if (this.reverseTime >= 30) {
            this.collisionState = 0;
            this.size = this.originalSize;
          }
        }
      }

      draw(ctx) {
        // Determine drawing properties based on state
        let currentAlpha = this.alpha;
        let currentSize = this.size;
        let currentGlowSize = this.glowSize;
        let currentColor = this.color;
        
        // Apply pulse effect
        const pulseEffect = Math.sin(this.pulse) * 0.2 + 1;
        currentSize *= pulseEffect;
        
        // Modify appearance based on collision state
        if (this.collisionState === 1) {
          // During collision: enhance glow and color
          const progress = this.collisionTime / this.collisionDuration;
          const colorShift = Math.floor(progress * 60); // Shift hue during collision
          
          // Extract base hue from color
          const hueMatch = this.color.match(/hsl\((\d+)/);
          if (hueMatch) {
            const baseHue = parseInt(hueMatch[1]);
            currentColor = `hsl(${(baseHue + colorShift) % 360}, 90%, 70%)`;
          }
          
          currentGlowSize = this.glowSize * (1.5 + progress);
          currentAlpha = Math.min(1, this.alpha * (1.3 + progress * 0.7));
        } else if (this.collisionState === 2) {
          // During reverse: shift color back and reduce glow
          const progress = this.reverseTime / 30;
          
          // Shift color back to original
          const hueMatch = this.color.match(/hsl\((\d+)/);
          if (hueMatch) {
            const baseHue = parseInt(hueMatch[1]);
            const colorShift = Math.floor((1 - progress) * 60);
            currentColor = `hsl(${(baseHue + colorShift) % 360}, ${80 + progress * 10}%, ${65 - progress * 10}%)`;
          }
          
          currentGlowSize = this.glowSize * (1.5 - progress);
        }
        
        // Draw glow effect
        ctx.beginPath();
        ctx.arc(this.x, this.y, currentGlowSize, 0, Math.PI * 2);
        const glow = ctx.createRadialGradient(this.x, this.y, 0, this.x, this.y, currentGlowSize);
        
        // Extract color components for glow
        const colorParts = currentColor.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
        if (colorParts) {
          const [_, hue, saturation, lightness] = colorParts;
          glow.addColorStop(0, `hsla(${hue}, ${saturation}%, ${lightness}%, ${currentAlpha * 0.7})`);
          glow.addColorStop(1, `hsla(${hue}, ${saturation}%, ${lightness}%, 0)`);
        } else {
          glow.addColorStop(0, `${currentColor.replace(')', ', ' + (currentAlpha * 0.7) + ')')}`);
          glow.addColorStop(1, `${currentColor.replace(')', ', 0)')}`);
        }
        
        ctx.fillStyle = glow;
        ctx.fill();
        
        // Draw particle
        ctx.beginPath();
        ctx.arc(this.x, this.y, currentSize, 0, Math.PI * 2);
        ctx.fillStyle = currentColor;
        ctx.globalAlpha = currentAlpha;
        ctx.fill();
        ctx.globalAlpha = 1;
        
        // Draw connections
        this.connected.forEach(conn => {
          ctx.beginPath();
          ctx.moveTo(this.x, this.y);
          ctx.lineTo(conn.particle.x, conn.particle.y);
          
          // Create gradient for connection line
          const gradient = ctx.createLinearGradient(this.x, this.y, conn.particle.x, conn.particle.y);
          
          // Extract color components
          const myColorParts = currentColor.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
          const theirColorParts = conn.particle.color.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
          
          if (myColorParts && theirColorParts) {
            gradient.addColorStop(0, `hsla(${myColorParts[1]}, ${myColorParts[2]}%, ${myColorParts[3]}%, ${conn.opacity * currentAlpha * 0.5})`);
            gradient.addColorStop(1, `hsla(${theirColorParts[1]}, ${theirColorParts[2]}%, ${theirColorParts[3]}%, ${conn.opacity * conn.particle.alpha * 0.5})`);
          } else {
            gradient.addColorStop(0, `${currentColor.replace(')', ', ' + (conn.opacity * currentAlpha * 0.5) + ')')}`);
            gradient.addColorStop(1, `${conn.particle.color.replace(')', ', ' + (conn.opacity * conn.particle.alpha * 0.5) + ')')}`);
          }
          
          ctx.strokeStyle = gradient;
          ctx.lineWidth = Math.min(this.size, conn.particle.size) * 0.3 * conn.opacity;
          ctx.stroke();
        });
      }
    }

    // Create particles
    const particles = [];
    const particleCount = getParticleCount();
    
    for (let i = 0; i < particleCount; i++) {
      particles.push(new Particle());
    }

    // Animation loop with performance optimizations
    let lastTime = 0;
    const fps = isMobile ? 30 : 60; // Lower FPS on mobile for better performance
    const interval = 1000 / fps;
    
    function animate(timestamp) {
      // Throttle frame rate for performance
      if (timestamp - lastTime < interval) {
        requestAnimationFrame(animate);
        return;
      }
      
      lastTime = timestamp;
      
      // Clear canvas completely
      ctx.fillStyle = 'rgba(0, 0, 0, 1)';
      ctx.fillRect(0, 0, dimensions.width, dimensions.height);
      
      // First update all particles
      particles.forEach(particle => {
        particle.update(particles);
      });
      
      // Then draw all particles and their connections
      particles.forEach(particle => {
        particle.draw(ctx);
      });

      requestAnimationFrame(animate);
    }

    animate(0);

    // Cleanup
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [isMobile, isTablet, density, dimensions.width, dimensions.height]);

  return (
    <CanvasContainer>
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
          display: 'block',
        }}
      />
    </CanvasContainer>
  );
};

export default AnimatedBackground;