// frontend/src/pages/Welcome.js

import React, { useEffect, useState, useContext } from 'react';
import { 
  Container, Box, Typography, Button, Grid, Card, CardContent, 
  Alert, CircularProgress, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle, Tabs, Tab,
  IconButton, Snackbar, Paper
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ImageIcon from '@mui/icons-material/Image';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import api from '../services/api'; // Ensure this path is correct
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { format, subDays } from 'date-fns';
import { generateContentStudioCodeSamples } from '../utils/contentStudioCodeSamples';

const Welcome = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Indicates if fetching user data is in progress
  const [error, setError] = useState(null); // Stores errors related to fetching user data
  const [dailyUsageData, setDailyUsageData] = useState([]);
  const [modelCostData, setModelCostData] = useState([]);
  const navigate = useNavigate();

  // Access logout from AuthContext
  const { logout } = useContext(AuthContext);

  // State for API Key Regeneration Dialog
  const [open, setOpen] = useState(false);

  // State for showing/hiding API key
  const [showApiKey, setShowApiKey] = useState(false);

  // State for selected programming language tab
  const [selectedLanguage, setSelectedLanguage] = useState('nodejs');

  // State for code dialog
  const [codeDialogOpen, setCodeDialogOpen] = useState(false);
  const [codeSamples, setCodeSamples] = useState(null);
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(0);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);

  // Code examples organized by language
  const codeExamples = {
    nodejs: {
      code: `import OpenAI from "openai";

const openai = new OpenAI({
    base_url: "https://api.brilliantai.co",
    api_key: "Your-API-Key"
});

const completion = await openai.chat.completions.create({
    model: "mistral-nemo",
    messages: [
        {"role": "user", "content": "write a haiku about ai"}
    ]
});`,
      language: 'javascript'
    },
    python: {
      code: `from openai import OpenAI

client = OpenAI(
    base_url="https://api.brilliantai.co",
    api_key="Your-API-Key"
)

completion = client.chat.completions.create(
    model="mistral-nemo",
    messages=[
        {"role": "user", "content": "write a haiku about ai"}
    ]
)`,
      language: 'python'
    },
    curl: {
      code: `curl https://api.brilliantai.co/chat/completions \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer $OPENAI_API_KEY" \\
-d '{
    "model": "mistral-nemo",
    "messages": [
        {"role": "user", "content": "write a haiku about ai"}
    ]
}'`,
      language: 'bash'
    }
  };

  const embeddingExamples = {
    nodejs: {
      code: `import OpenAI from "openai";

const openai = new OpenAI({
    base_url: "https://api.brilliantai.co",
    api_key: "Your-API-Key"
});

const response = await openai.embeddings.create({
    model: "snowflake-arctic-embed-l-v2.0",
    input: "The quick brown fox jumps over the lazy dog"
});

const embedding = response.data[0].embedding;`,
      language: 'javascript'
    },
    python: {
      code: `from openai import OpenAI

client = OpenAI(
    base_url="https://api.brilliantai.co",
    api_key="Your-API-Key"
)

response = client.embeddings.create(
    model="snowflake-arctic-embed-l-v2.0",
    input="The quick brown fox jumps over the lazy dog"
)

embedding = response.data[0].embedding`,
      language: 'python'
    },
    curl: {
      code: `curl https://api.brilliantai.co/embeddings \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer $OPENAI_API_KEY" \\
-d '{
    "model": "snowflake-arctic-embed-l-v2.0",
    "input": "The quick brown fox jumps over the lazy dog"
}'`,
      language: 'bash'
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Fetch the current user's data
        const response = await api.get('/auth/me');
        console.log('User data:', response.data);
        setUser(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load user data. Redirecting to login...');
        setLoading(false);
        // Optionally, log out the user if fetching data fails
        setTimeout(() => {
          logout();
          navigate('/login');
        }, 3000);
      }
    };
    fetchUser();

    // Fetch usage data
    const fetchUsageData = async () => {
      try {
        const endDate = new Date();
        const startDate = subDays(endDate, 7);
        const [dailyRes, modelRes] = await Promise.all([
          api.get(`/usage/daily?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`),
          api.get(`/usage/models?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`)
        ]);
        setDailyUsageData(dailyRes.data || []);
        setModelCostData(modelRes.data || []);
      } catch (error) {
        console.error('Error fetching usage data:', error);
      }
    };
    fetchUsageData();
  }, [logout, navigate]);

  // Handlers for API Key Regeneration Dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRegenerateApiKey = async () => {
    try {
      const response = await api.post('/auth/api-keys/regenerate'); // New regenerate endpoint
      const newApiKey = response.data.apiKey;
      setUser((prevUser) => ({
        ...prevUser,
        apiKey: { ...prevUser.apiKey, key: newApiKey, created_at: new Date().toISOString() }
      }));
      setShowApiKey(false); // Hide the API key after regeneration
      handleClose();
      
    } catch (err) {
      console.error('Error regenerating API key:', err);
      // Optionally, set an error state to display to the user
      setError('Failed to regenerate API key.');
    }
  };

  // Handler to Copy API Key to Clipboard
  const handleCopy = () => {
    if (user && user.apiKey && user.apiKey.key) {
      navigator.clipboard.writeText(user.apiKey.key).then(() => {
        // Optionally, display a success message
      }, () => {
        // Optionally, handle the error
      });
    }
  };

  // Handler to Toggle API Key Visibility
  const handleToggleShow = () => {
    setShowApiKey((prev) => !prev);
  };

  // Handler for Tab Change
  const handleTabChange = (event, newValue) => {
    setSelectedLanguage(newValue);
  };

  // Function to handle getting code samples for media APIs
  const handleGetMediaCode = (mediaType) => {
    let params = {};
    let endpoint = '';
    
    if (mediaType === 'video-generation') {
      endpoint = 'videos';
      params = {
        model: "wan-v1",
        prompt: "A beautiful sunset over the ocean",
        quality: "medium",
        fps: 24
      };
    } else if (mediaType === 'image-generation') {
      endpoint = 'images';
      params = {
        model: "glimmer-v1",
        prompt: "A beautiful sunset over the ocean",
        aspect_ratio: "square",
        image_format: "png"
      };
    }
    
    const codeSamples = generateContentStudioCodeSamples(endpoint, params);
    setCodeSamples(codeSamples);
    setSelectedModel(mediaType);
    setCodeDialogOpen(true);
  };

  // Function to handle getting code samples
  const handleGetCode = async (model) => {
    // Check if this is a media API model
    if (model === 'video-generation' || model === 'image-generation') {
      handleGetMediaCode(model);
      return;
    }
    
    try {
      const res = await api.post('/playground/get-code', {
        model: model,
        messages: [
          {
            role: 'system',
            content: 'You are a helpful assistant'
          },
          {
            role: 'user',
            content: 'Hello, how are you?'
          }
        ],
        temperature: 0.7,
        max_tokens: 150,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
      });
      setCodeSamples(res.data);
      setSelectedModel(model);
      setCodeDialogOpen(true);
    } catch (err) {
      setError(err.message || 'Failed to generate code samples');
    }
  };

  // Function to handle copying code
  const handleCopyCode = () => {
    if (!codeSamples) return;
    
    const code = selectedLanguageCode === 0 ? codeSamples.python || '' :
                selectedLanguageCode === 1 ? codeSamples.javascript || '' :
                codeSamples.curl || '';
    
    if (code.trim()) {
      navigator.clipboard.writeText(code);
      setShowCopyNotification(true);
    }
  };

  // Function to handle tab change
  const handleTabChangeCode = (event, newValue) => {
    setSelectedLanguageCode(newValue);
  };

  // Define languageModels 
  const languageModels = [
    {
      name: 'DeepSeek-R1',
      model_name: 'DeepSeek-R1',
      description: 'State-of-the-art reasoning model',
      features: ['Advanced reasoning', 'Text and code generation', 'High accuracy'],
      type: 'Large Model',
      price: '$0.0 / Million Tokens'
    },
    {
      name: 'LLAMA 3.3 70B',
      model_name: 'llama-3.3-70b',
      description: 'Frontier model for complex tasks',
      features: ['Advanced reasoning', 'Text and code generation', 'High accuracy'],
      type: 'Large Model',
      price: '$0.80 / Million Tokens'
    },
    {
      name: 'LLAMA 3.2 8B',
      model_name: 'llama-3.2-8b',
      description: 'Fast and efficient model for basic tasks',
      features: ['Quick responses', 'Cost-effective', 'General purpose'],
      type: 'Base Model',
      price: '$0.20 / Million Tokens'
    },
    {
      name: 'Mistral Nemo',
      model_name: 'mistral-nemo',
      description: 'Advanced model with strong reasoning capabilities',
      features: ['High efficiency', 'Strong reasoning', 'General purpose'],
      type: 'Advanced Model',
      price: '$0.30 / Million Tokens'
    }
  ];

  if (loading) {
    // Display a loading spinner while fetching user data
    return (
      <Container maxWidth="lg" sx={{ py: 4, textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading your information...
        </Typography>
      </Container>
    );
  }

  if (error) {
    // Display an error message if fetching user data fails
    return (
      <Container maxWidth="lg" sx={{ py: 4, textAlign: 'center' }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  // Function to mask API key (e.g., show first 4 and last 4 characters)
  const maskApiKey = (key) => {
    if (key.length <= 8) {
      return '*'.repeat(key.length);
    }
    const start = key.slice(0, 4);
    const end = key.slice(-4);
    const masked = '*'.repeat(key.length - 8);
    return `${start}${masked}${end}`;
  };

  return (
    <Container maxWidth="lg" sx={{ 
      py: 4,
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none',
      'scrollbarWidth': 'none'
    }}>
      {/* BrilliantAI Studio Introduction Section */}
      <Box 
        sx={{ 
          position: 'relative', 
          height: '600px', 
          width: '100%', 
          mb: 6, 
          overflow: 'hidden',
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)'
        }}
      >
        {/* Video Background */}
        <Box
          component="video"
          autoPlay
          loop
          muted
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 0,
            opacity: 0.7,
            filter: 'brightness(0.4)'
          }}
        >
          <source src="https://brilliantai-files.b-cdn.net/jellyfish.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </Box>
        
        {/* Content Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
          }}
        >
          <Typography 
            variant="h2" 
            align="center" 
            sx={{ 
              fontWeight: 700, 
              color: 'white',
              mb: 2,
              textShadow: '0 2px 4px rgba(0,0,0,0.5)'
            }}
          >
            Introducing BrilliantAI Studio
          </Typography>
          
          <Typography 
            variant="h5" 
            align="center" 
            sx={{ 
              maxWidth: '800px', 
              mb: 6, 
              color: 'grey.300',
              textShadow: '0 1px 2px rgba(0,0,0,0.5)'
            }}
          >
            Create stunning images and videos with our powerful AI APIs and intuitive studio interface. 
            Transform your creative vision into reality with just a few clicks.
          </Typography>
          
          <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
            <Button 
              variant="contained" 
              size="large"
              color="primary"
              startIcon={<AutoAwesomeIcon />}
              component={RouterLink}
              to="/studio"
              sx={{ 
                py: 1.5, 
                px: 4, 
                borderRadius: 2,
                fontWeight: 600,
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  bgcolor: 'primary.dark'
                }
              }}
            >
              Try Studio
            </Button>
            
            <Button 
              variant="outlined" 
              size="large"
              color="primary"
              startIcon={<CodeIcon />}
              onClick={() => window.open('https://docs.brilliantai.co', '_blank')}
              sx={{ 
                py: 1.5, 
                px: 4, 
                borderRadius: 2,
                fontWeight: 600,
                borderWidth: 2,
                transition: 'all 0.2s ease',
                '&:hover': {
                  borderWidth: 2,
                  bgcolor: 'rgba(255,255,255,0.05)'
                }
              }}
            >
              Use API
            </Button>
          </Box>
          
          <Box sx={{ position: 'absolute', bottom: 20, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <IconButton 
              sx={{ 
                color: 'white', 
                animation: 'bounce 2s infinite',
                '@keyframes bounce': {
                  '0%, 20%, 50%, 80%, 100%': {
                    transform: 'translateY(0)'
                  },
                  '40%': {
                    transform: 'translateY(-20px)'
                  },
                  '60%': {
                    transform: 'translateY(-10px)'
                  }
                }
              }}
              onClick={() => {
                window.scrollTo({
                  top: 650,
                  behavior: 'smooth'
                });
              }}
              aria-label="Scroll down"
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Developer Quickstart and Account Summary Section */}
      <Grid container spacing={4} sx={{ mb: 6 }}>
        {/* Developer Quickstart Section */}
        <Grid item xs={12}>
          <Card sx={{ 
            bgcolor: 'grey.900',
            color: 'white',
            border: '1px solid',
            borderColor: 'grey.800',
            boxShadow: 'none',
            height: '100%'
          }}>
            <CardContent>
              <Grid container spacing={3} alignItems="center" style={{ height: '100%' }}>
                <Grid 
                  item 
                  xs={12} 
                  md={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2 }}>Developer Quickstart</Typography>
                  <Typography variant="body2" color="grey.400" sx={{ mb: 2 }}>
                    Set up your environment and make your first API request in minutes
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AccessTimeIcon sx={{ fontSize: 16, color: 'grey.500' }} />
                    <Typography variant="body2" color="grey.500">5 min</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  {/* Tabs for Language Selection */}
                  <Tabs
                    value={selectedLanguage}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    sx={{ mb: 2 }}
                    aria-label="code examples"
                  >
                    <Tab label="Node.js" value="nodejs" />
                    <Tab label="Python" value="python" />
                    <Tab label="cURL" value="curl" />
                  </Tabs>
                  {/* Code Example */}
                  <Box sx={{ mt: 2 }}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end', 
                      mb: 1,
                      bgcolor: '#282C34',
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      p: 1
                    }}>
                      <Button 
                        size="small" 
                        sx={{ color: 'grey.400' }} 
                        onClick={() => navigator.clipboard.writeText(codeExamples[selectedLanguage].code)}
                        aria-label="Copy Code Example"
                      >
                        Copy
                      </Button>
                    </Box>
                    <SyntaxHighlighter
                      language={codeExamples[selectedLanguage].language}
                      style={oneDark}
                      customStyle={{
                        margin: 0,
                        padding: '16px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                      }}
                    >
                      {codeExamples[selectedLanguage].code}
                    </SyntaxHighlighter>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Account Summary Section */}
        <Grid item xs={12}>
          <Card sx={{ 
            bgcolor: 'grey.900',
            color: 'white',
            border: '1px solid',
            borderColor: 'grey.800',
            boxShadow: 'none'
          }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Account Summary</Typography>
              {/* Split Account Summary into two distinct columns */}
              <Grid container spacing={2}>
                {/* Left Column: API Key */}
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    bgcolor: 'grey.800',
                    p: 2,
                    borderRadius: 1,
                    height: '100%',
                  }}>
                    {user.apiKey ? (
                      <>
                        <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>API Key:</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <Typography variant="body2" sx={{ fontFamily: 'monospace', color: 'grey.400', wordBreak: 'break-all' }}>
                            {showApiKey ? user.apiKey.key : maskApiKey(user.apiKey.key)}
                          </Typography>
                          <Button 
                            size="small" 
                            variant="outlined" 
                            color="primary"
                            onClick={handleCopy}
                            aria-label="Copy API Key"
                          >
                            COPY
                          </Button>
                          <Button 
                            size="small" 
                            variant="outlined" 
                            color="secondary"
                            onClick={handleToggleShow}
                            aria-label={showApiKey ? "Hide API Key" : "Show API Key"}
                          >
                            {showApiKey ? 'HIDE' : 'SHOW'}
                          </Button>
                        </Box>
                        <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                          Regenerate API Key
                        </Button>
                      </>
                    ) : (
                      <>
                        <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>API Key:</Typography>
                        <Typography variant="body2" color="grey.400" sx={{ mb: 2 }}>
                          No API Key found.
                        </Typography>
                        <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                          Generate API Key
                        </Button>
                      </>
                    )}
                  </Box>
                </Grid>

                {/* Right Column: Endpoint and Credit Balance */}
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    bgcolor: 'grey.800',
                    p: 2,
                    borderRadius: 1,
                    height: '100%',
                  }}>
                    {/* Endpoint */}
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>Endpoint:</Typography>
                      <Typography variant="body2" color="grey.400">
                      https://api.brilliantai.co
                      </Typography>
                    </Box>
                    {/* Credit Balance */}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="body2" color="grey.400">
                        CREDIT BALANCE: {user.balance !== undefined ? `$${(user.balance / 100).toFixed(2)}` : 'N/A'}
                      </Typography>
                      <Button 
                      size="small" 
                      variant="outlined" 
                      color="primary"
                      onClick={() => navigate('/billing')} 
                      >
                        TOP UP
                        </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Media APIs Section */}
      <Box sx={{ mt: 4, mb: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" sx={{ fontWeight: 500 }}>Media APIs</Typography>
          <Button variant="text" color="primary">Pricing →</Button>
        </Box>
        <Grid container spacing={3}>
          {/* Video Card */}
          <Grid item xs={12} md={6}>
            <Card sx={{ 
              height: '100%',
              bgcolor: 'grey.900',
              color: 'white',
              border: '1px solid',
              borderColor: 'grey.800',
              boxShadow: 'none',
              position: 'relative'
            }}>
              <Button
                variant="text"
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'primary.main'
                }}
                onClick={() => handleGetCode('video-generation')}
                startIcon={<CodeIcon />}
              >
                Get Code
              </Button>
              <CardContent>
                <Typography variant="h6" gutterBottom>Video Generation</Typography>
                <Typography variant="body2" color="grey.400" sx={{ mb: 2 }}>
                  Hyper-realistic text-to-video generation
                </Typography>
                <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>
                  Low Quality Mode ($0.7 / Video)
                </Typography>
                <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>
                  Medium Quality Mode ($0.7 / Video)
                </Typography>
                <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>
                  High Quality Mode ($1.2 / Video)
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          {/* Image Card */}
          <Grid item xs={12} md={6}>
            <Card sx={{ 
              height: '100%',
              bgcolor: 'grey.900',
              color: 'white',
              border: '1px solid',
              borderColor: 'grey.800',
              boxShadow: 'none',
              position: 'relative'
            }}>
              <Button
                variant="text"
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'primary.main'
                }}
                onClick={() => handleGetCode('image-generation')}
                startIcon={<CodeIcon />}
              >
                Get Code
              </Button>
              <CardContent>
                <Typography variant="h6" gutterBottom>Image Generation</Typography>
                <Typography variant="body2" color="grey.400" sx={{ mb: 2 }}>
                  High Quality Text-to-Image Generation
                </Typography>
                <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>
                  Photorealistic images
                </Typography>
                <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>
                  Artistic styles
                </Typography>
                <Typography variant="body2" color="grey.400" sx={{ mb: 1 }}>
                  High resolution output
                </Typography>
                <Typography variant="body2" color="primary" sx={{ mt: 2 }}>
                  $0.07 / Image
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
          <Button 
          variant="outlined" 
          color="primary"
          onClick={() => navigate('/studio')} 
          >
            Try in Studio
          </Button>
          <Button variant="outlined" color="primary">Documentation</Button>
        </Box>
      </Box>

      {/* Language Models Section */}
      <Box sx={{ mt: 4, mb: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" sx={{ fontWeight: 500 }}>Language Models</Typography>
          <Button variant="text" color="primary">Pricing →</Button>
        </Box>
        <Grid container spacing={3}>
          {languageModels.map((model, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ 
                height: '100%',
                bgcolor: 'grey.900',
                color: 'white',
                border: '1px solid',
                borderColor: 'grey.800',
                boxShadow: 'none',
                position: 'relative'
              }}>
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: 'primary.main'
                  }}
                  onClick={() => handleGetCode(model.model_name)}
                  startIcon={<CodeIcon />}
                >
                  Get Code
                </Button>
                <CardContent>
                  <Typography variant="h6" gutterBottom>{model.name}</Typography>
                  <Typography variant="body2" color="grey.400" sx={{ mb: 2 }}>
                    {model.description}
                  </Typography>
                  {model.features.map((feature, idx) => (
                    <Typography key={idx} variant="body2" color="grey.400" sx={{ mb: 1 }}>
                      {feature}
                    </Typography>
                  ))}
                  <Typography variant="body2" color="primary" sx={{ mt: 2 }}>
                    {model.price}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
          <Button 
          variant="outlined" 
          color="primary"
          onClick={() => navigate('/playground')} 
          >
            Try in Playground
          </Button>
          <Button variant="outlined" color="primary">Examples & Prompting Guide</Button>
        </Box>
      </Box>

      {/* Embeddings Section */}
      <Box sx={{ mt: 4, mb: 6 }}>
        <Typography variant="h5" gutterBottom>Embeddings</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ 
              bgcolor: 'grey.900',
              color: 'white',
              border: '1px solid',
              borderColor: 'grey.800',
              boxShadow: 'none'
            }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Box>
                      <Typography variant="h6">Snowflake Arctic Embed L v2.0</Typography>
                      <Typography variant="body2" color="grey.400" sx={{ mt: 1 }}>
                        High-performance embedding model for text similarity and semantic search
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" color="grey.400">
                          Pricing: $0.015/1M tokens
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setSelectedModel('snowflake-arctic-embed-l-v2.0');
                        setCodeDialogOpen(true);
                        setCodeSamples({
                          python: `from openai import OpenAI

client = OpenAI(
    base_url="https://api.brilliantai.co",
    api_key="${user.apiKey.key}"
)

response = client.embeddings.create(
    model="snowflake-arctic-embed-l-v2.0",
    input="The quick brown fox jumps over the lazy dog"
)

embedding = response.data[0].embedding`,
                          javascript: `import OpenAI from "openai";

const openai = new OpenAI({
    base_url: "https://api.brilliantai.co",
    api_key: "${user.apiKey.key}"
});

const response = await openai.embeddings.create({
    model: "snowflake-arctic-embed-l-v2.0",
    input: "The quick brown fox jumps over the lazy dog"
});

const embedding = response.data[0].embedding;`,
                          curl: `curl https://api.brilliantai.co/embeddings \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer ${user.apiKey.key}" \\
-d '{
    "model": "snowflake-arctic-embed-l-v2.0",
    "input": "The quick brown fox jumps over the lazy dog"
}'`
                        });
                      }}
                      startIcon={<CodeIcon />}
                    >
                      View Code
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Embeddings Developer Quickstart */}
          <Grid item xs={12}>
            <Card sx={{ 
              bgcolor: 'grey.900',
              color: 'white',
              border: '1px solid',
              borderColor: 'grey.800',
              boxShadow: 'none'
            }}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid 
                    item 
                    xs={12} 
                    md={4}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 2 }}>Embeddings Quickstart</Typography>
                    <Typography variant="body2" color="grey.400" sx={{ mb: 2 }}>
                      Learn how to generate embeddings for semantic search and similarity matching
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AccessTimeIcon sx={{ fontSize: 16, color: 'grey.500' }} />
                      <Typography variant="body2" color="grey.500">3 min</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {/* Tabs for Language Selection */}
                    <Tabs
                      value={selectedLanguage}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      sx={{ mb: 2 }}
                      aria-label="code examples"
                    >
                      <Tab label="Node.js" value="nodejs" />
                      <Tab label="Python" value="python" />
                      <Tab label="cURL" value="curl" />
                    </Tabs>
                    {/* Code Example */}
                    <Box sx={{ mt: 2 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        mb: 1,
                        bgcolor: '#282C34',
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        p: 1
                      }}>
                        <Button 
                          size="small" 
                          sx={{ color: 'grey.400' }} 
                          onClick={() => navigator.clipboard.writeText(embeddingExamples[selectedLanguage].code)}
                          aria-label="Copy Code Example"
                        >
                          Copy
                        </Button>
                      </Box>
                      <SyntaxHighlighter
                        language={embeddingExamples[selectedLanguage].language}
                        style={oneDark}
                        customStyle={{
                          margin: 0,
                          padding: '16px',
                          borderRadius: '4px',
                          fontSize: '14px',
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                        }}
                      >
                        {embeddingExamples[selectedLanguage].code}
                      </SyntaxHighlighter>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Dashboard Preview Section */}
      <Box sx={{ mb: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" sx={{ fontWeight: 500 }}>Usage Overview</Typography>
          <Button variant="text" color="primary" onClick={() => navigate('/dashboard')}>View Full Dashboard →</Button>
        </Box>
        <Grid container spacing={3}>
          {/* Daily Usage Chart */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, bgcolor: 'grey.900', border: '1px solid', borderColor: 'grey.800' }}>
              <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                Daily Token Usage (Last 7 Days)
              </Typography>
              {dailyUsageData.length > 0 ? (
                <LineChart width={800} height={300} data={dailyUsageData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" stroke="#fff" />
                  <YAxis stroke="#fff" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="input_tokens" stroke="#8884d8" name="Input Tokens" />
                  <Line type="monotone" dataKey="output_tokens" stroke="#82ca9d" name="Output Tokens" />
                </LineChart>
              ) : (
                <Typography color="grey.400" align="center" sx={{ py: 4 }}>
                  No usage data available
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* Cost Summary */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, bgcolor: 'grey.900', border: '1px solid', borderColor: 'grey.800' }}>
              <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                Cost Summary
              </Typography>
              {modelCostData.length > 0 ? (
                <Grid container spacing={2}>
                  {modelCostData.map((model) => (
                    <Grid item xs={12} sm={6} md={3} key={model.model}>
                      <Paper elevation={2} sx={{ p: 2, bgcolor: 'grey.800' }}>
                        <Typography variant="subtitle1" sx={{ color: 'white' }}>{model.model}</Typography>
                        <Typography variant="h6" sx={{ color: 'primary.main' }}>${model.cost.toFixed(2)}</Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography color="grey.400" align="center" sx={{ py: 2 }}>
                  No cost data available
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Developer Support Section */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: 500, mb: 3 }}>Developer Support</Typography>
        <Grid container spacing={3}>
          {['Documentation', 'Example Apps', 'Prompt Guide'].map((item, index) => {
            const link =
              item === 'Documentation'
                ? 'https://docs.brilliantai.co/'
                : item === 'Example Apps'
                  ? 'https://docs.brilliantai.co/examples/chatbot'
                : item === 'Prompt Guide'
                  ? 'https://docs.brilliantai.co/api/prompt-engineering'
                  : '#';

            return (
              <Grid item xs={6} md={4} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    bgcolor: 'grey.900',
                    color: 'white',
                    border: '1px solid',
                    borderColor: 'grey.800',
                    boxShadow: 'none',
                    cursor: 'pointer',
                    '&:hover': {
                      borderColor: 'primary.main',
                    }
                  }}
                  onClick={() => window.open(link, '_blank')}
                >
                  <CardContent>
                    <Typography variant="h6">{item}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      {/* Status Section */}
      

      {/* API Key Regeneration Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Regenerate API Key</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Regenerating your API key will replace your existing key with a new one. This action cannot be undone. Make sure to update your applications with the new key after regeneration.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleRegenerateApiKey} variant="contained" color="primary">
            Regenerate
          </Button>
        </DialogActions>
      </Dialog>

      {/* Code Dialog */}
      <Dialog open={codeDialogOpen} onClose={() => setCodeDialogOpen(false)}>
        <DialogTitle>Code Samples for {selectedModel}</DialogTitle>
        <DialogContent>
          <Tabs
            value={selectedLanguageCode}
            onChange={handleTabChangeCode}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            sx={{ mb: 2 }}
            aria-label="code examples"
          >
            <Tab label="Python" value={0} />
            <Tab label="JavaScript" value={1} />
            <Tab label="cURL" value={2} />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end', 
              mb: 1,
              bgcolor: '#282C34',
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              p: 1
            }}>
              <Button 
                size="small" 
                sx={{ color: 'grey.400' }} 
                onClick={handleCopyCode}
                aria-label="Copy Code Example"
              >
                Copy
              </Button>
            </Box>
            <SyntaxHighlighter
              language={selectedLanguageCode === 0 ? 'python' : selectedLanguageCode === 1 ? 'javascript' : 'bash'}
              style={oneDark}
              customStyle={{
                margin: 0,
                padding: '16px',
                borderRadius: '4px',
                fontSize: '14px',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              {codeSamples ? (
                selectedLanguageCode === 0 ? codeSamples.python || '' :
                selectedLanguageCode === 1 ? codeSamples.javascript || '' :
                codeSamples.curl || ''
              ) : ''}
            </SyntaxHighlighter>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCodeDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Copy Notification */}
      <Snackbar
        open={showCopyNotification}
        autoHideDuration={2000}
        onClose={() => setShowCopyNotification(false)}
        message="Code copied to clipboard!"
      />
    </Container>
  );
};

export default Welcome;