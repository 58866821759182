// src/components/Navbar.js
import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Navbar = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  // Check if the current path matches the button's path
  const isActive = (path) => {
    // For home path, only match exact '/'
    if (path === '/' && location.pathname === '/') return true;
    // For other paths, match the beginning of the pathname
    return path !== '/' && location.pathname.startsWith(path);
  };

  // Button style function - applies highlight if active
  const getButtonStyle = (path) => ({
    backgroundColor: isActive(path) ? 'rgba(255, 255, 255, 0.15)' : 'transparent',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: isActive(path) 
        ? 'rgba(255, 255, 255, 0.25)' 
        : 'rgba(255, 255, 255, 0.08)',
    },
    mx: 0.5, // Add some margin between buttons
  });

  return (
    <AppBar position="static" sx={{ width: '100%', maxWidth: '100vw' }}>
      <Toolbar sx={{ width: '100%', overflowX: 'hidden' }}>
        {/* Logo and App Name */}
        <Box
          component={Link}
          to="/"
          sx={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'inherit',
            flexGrow: 1
          }}
        >
          <Box
            component="img"
            src="/Brilliant (1).svg"
            alt="BrilliantAI Logo"
            sx={{
              height: 50,
              marginRight: 1,
              filter: 'brightness(0) invert(1)', // This makes the logo white to match navbar text
              transition: 'filter 0.3s ease' // Smooth transition for any color changes
            }}
          />
          <Typography variant="h6" component="div">
            BrilliantAI
          </Typography>
        </Box>

        {/* Conditionally Render Menu Items */}
        {isAuthenticated ? (
          <Box sx={{ display: 'flex' }}>
            <Button color="inherit" component={Link} to="/welcome" sx={getButtonStyle('/welcome')}>
              Welcome
            </Button>
            <Button color="inherit" component={Link} to="/profile" sx={getButtonStyle('/profile')}>
              Profile
            </Button>
            <Button color="inherit" component={Link} to="/billing" sx={getButtonStyle('/billing')}>
              Billing
            </Button>
            <Button color="inherit" component={Link} to="/playground" sx={getButtonStyle('/playground')}>
              LLM Playground
            </Button>
            <Button color="inherit" component={Link} to="/studio" sx={getButtonStyle('/studio')}>
              Studio
            </Button>
            <Button color="inherit" component={Link} to="/dashboard" sx={getButtonStyle('/dashboard')}>
              Usage
            </Button>
            <Button color="inherit" onClick={handleLogout} sx={{ mx: 0.5 }}>
              Logout
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <Button color="inherit" component={Link} to="/" sx={getButtonStyle('/')}>
              Home
            </Button>
            <Button color="inherit" component={Link} to="/signup" sx={getButtonStyle('/signup')}>
              Sign Up
            </Button>
            <Button color="inherit" component={Link} to="/login" sx={getButtonStyle('/login')}>
              Login
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;