import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './theme';

// For improved SEO with react-snap prerendering
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  // If the app is being hydrated after prerendering by react-snap
  hydrate(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  // First render in development or if prerendering failed
  render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>,
    rootElement
  );
}