import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Paper,
  Stack,
  styled,
  Alert,
  CircularProgress,
  Grid,
  Tabs,
  Tab,
  IconButton,
  Chip,
  Card,
  CardContent,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AlertTitle,
  FormControl,
  InputLabel,
  Skeleton,
  LinearProgress,
  Divider,
  Container,
  Tooltip,
  InputAdornment,
  Menu,
  ListItemIcon,
  ListItemText,
  ListItem
} from '@mui/material';
import {
  Image as ImageIcon,
  Videocam as VideocamIcon,
  ContentCopy as ContentCopyIcon,
  Clear as ClearIcon,
  Code as CodeIcon,
  Close as CloseIcon,
  AutoAwesome as AutoAwesomeIcon,
  Download as DownloadIcon,
  AddCircle as AddCircleIcon,
  Send as SendIcon,
  Public as PublicIcon,
  Share as ShareIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
  Link as LinkIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import api from '../services/api';
import { generateContentStudioCodeSamples } from '../utils/contentStudioCodeSamples';

// ------------------------------
// Styled Components
// ------------------------------

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  padding: theme.spacing(1.5, 2),
  minWidth: 120,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    fontWeight: 600
  }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTabs-indicator': {
    height: 3,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3
  }
}));

const PromptChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    boxShadow: theme.shadows[1]
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.light
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main
  }
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.paper,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.light
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main
    }
  }
}));

const CreateButton = styled(Button)(({ theme }) => ({
  height: '100%',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  fontWeight: 500,
  boxShadow: 'none',
  textTransform: 'none',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  whiteSpace: 'nowrap',
  '&:hover': {
    boxShadow: 'none'
  }
}));

const SidebarButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  fontWeight: 500,
  boxShadow: theme.shadows[1],
  width: '100%'
}));

const ContentCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4]
  }
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
}));

const StyledVideo = styled('video')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
}));

const SidebarSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1]
}));

const StyledSidebar = styled(Box)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.default,
  borderLeft: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
  width: 320,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  '-ms-overflow-style': 'none',
  'scrollbarWidth': 'none'
}));

// Type indicator (colored dot)
const TypeIndicator = styled(Box)(({ theme, contentType }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: contentType === 'image' ? theme.palette.success.main : theme.palette.warning.main,
  display: 'inline-block',
  marginRight: theme.spacing(0.5)
}));

// Share button for community showcase
const ShareButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(4px)',
  width: 36,
  height: 36,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  boxShadow: theme.shadows[2],
  transition: 'all 0.2s ease'
}));

// ------------------------------
// Main Component: ContentStudio
// ------------------------------

const ContentStudio = () => {
  // Tab, prompt, loading, error, code dialog and snackbar states
  const [tab, setTab] = useState(0);
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showCodeDialog, setShowCodeDialog] = useState(false);
  const [codeExamples, setCodeExamples] = useState(null);
  const [codeTab, setCodeTab] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Gallery and user data states
  const [generatedImages, setGeneratedImages] = useState([]);
  const [generatedVideos, setGeneratedVideos] = useState([]);
  const [userBalance, setUserBalance] = useState(0);
  const [balanceLoading, setBalanceLoading] = useState(true);

  // Community tab states
  const [communityContent, setCommunityContent] = useState([]);
  const [communityPage, setCommunityPage] = useState(0);
  const [loadingMoreCommunity, setLoadingMoreCommunity] = useState(false);
  const [hasMoreCommunity, setHasMoreCommunity] = useState(true);
  const communityContainerRef = React.useRef(null);

  // Generation settings
  const imageModel = 'glimmer-v1';
  const videoModel = 'wan-v1';
  const [imageSize, setImageSize] = useState('1:1');
  const [imageFormat, setImageFormat] = useState('WEBP');
  const [seed, setSeed] = useState(null);

  const [videoResolution, setVideoResolution] = useState('medium');
  const [videoFps, setVideoFps] = useState(24);

  // Example prompts
  const [imagePrompts, setImagePrompts] = useState([]);
  const [videoPrompts, setVideoPrompts] = useState([]);

  // Selected image/video for full view
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  // Share menu states
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [shareItemType, setShareItemType] = useState(null);
  const [shareItemData, setShareItemData] = useState(null);

  // Delete confirmation dialog states
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  // For progress calculation, track current time updated every second
  const [currentTime, setCurrentTime] = useState(Date.now());
  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(Date.now()), 1000);
    return () => clearInterval(timer);
  }, []);

  // Image dimensions for proper display in modal
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);
  const [imageContainerHeight, setImageContainerHeight] = useState('80vh');

  // ---------------------------
  // Gallery Refresh Functions
  // ---------------------------
  const fetchUserImages = async () => {
    try {
      const response = await api.get('/playground/images');
      setGeneratedImages(response.data);
    } catch (err) {
      console.error('Error fetching images:', err);
    }
  };

  const fetchUserVideos = async () => {
    try {
      const response = await api.get('/playground/videos');
      setGeneratedVideos(response.data);
    } catch (err) {
      console.error('Error fetching videos:', err);
    }
  };

  // Function to fetch community content (both images and videos)
  const fetchCommunityContent = async (page = 0, append = false) => {
    try {
      setLoadingMoreCommunity(true);
      const response = await api.get(`/playground/community-content?page=${page}&limit=20`);
      
      if (response.data.length < 20) {
        setHasMoreCommunity(false);
      }
      
      if (append) {
        setCommunityContent(prev => [...prev, ...response.data]);
      } else {
        setCommunityContent(response.data);
      }
      
      setCommunityPage(page);
    } catch (err) {
      console.error('Error fetching community content:', err);
      setError('Failed to load community content');
    } finally {
      setLoadingMoreCommunity(false);
    }
  };

  // ---------------------------
  // Initial Data Fetch on Mount
  // ---------------------------
  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const promptsResponse = await api.get('/studio/example-prompts');
        setImagePrompts(promptsResponse.data.image_prompts);
        setVideoPrompts(promptsResponse.data.video_prompts);
      } catch (err) {
        console.error('Failed to fetch prompts:', err);
        setError('Failed to load example prompts');
      }
    };
    fetchPrompts();
    fetchUserImages();
    fetchUserVideos();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get('/auth/me');
        setUserBalance(response.data.balance);
        setBalanceLoading(false);
      } catch (err) {
        console.error('Failed to fetch user balance:', err);
        setError('Failed to load user balance');
        setBalanceLoading(false);
      }
    };
    fetchUserData();
  }, []);

  // Effect for infinite scroll in community tab
  useEffect(() => {
    if (tab === 2 && communityContainerRef.current) {
      const handleScroll = () => {
        const container = communityContainerRef.current;
        if (container) {
          const { scrollTop, scrollHeight, clientHeight } = container;
          
          // If scrolled to near bottom (with a threshold)
          if (scrollTop + clientHeight >= scrollHeight - 200 && !loadingMoreCommunity && hasMoreCommunity) {
            fetchCommunityContent(communityPage + 1, true);
          }
        }
      };
      
      const scrollContainer = communityContainerRef.current;
      scrollContainer.addEventListener('scroll', handleScroll);
      
      return () => {
        if (scrollContainer) {
          scrollContainer.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [tab, communityPage, loadingMoreCommunity, hasMoreCommunity]);

  // ---------------------------
  // Polling Effects for In-Progress Items
  // ---------------------------
  useEffect(() => {
    if (generatedImages.some(img => img.status !== 'completed' && img.status !== 'failed')) {
      const timer = setTimeout(fetchUserImages, 3000);
      return () => clearTimeout(timer);
    }
  }, [generatedImages]);

  useEffect(() => {
    if (generatedVideos.some(video => video.status !== 'completed' && video.status !== 'failed')) {
      const timer = setTimeout(fetchUserVideos, 3000);
      return () => clearTimeout(timer);
    }
  }, [generatedVideos]);

  // ---------------------------
  // Handlers for Tab and Prompt
  // ---------------------------
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    
    if (newValue === 0) {
      setPrompt(imagePrompts[0] || '');
    } else if (newValue === 1) {
      setPrompt(videoPrompts[0] || '');
    } else if (newValue === 2 && communityContent.length === 0) {
      // Load community content when switching to community tab for the first time
      fetchCommunityContent();
    }
    
    setError('');
  };

  const handlePromptChipClick = (promptText) => {
    setPrompt(promptText);
  };

  // Key press handler for the prompt input
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent newline when pressing Enter without Shift
      if (tab === 0) {
        handleGenerateImage();
      } else {
        handleGenerateVideo();
      }
    }
  };

  // ---------------------------
  // Delete Handlers
  // ---------------------------
  const handleDeleteClick = (event, item, type) => {
    event.stopPropagation(); // Prevent opening the image/video modal
    setItemToDelete({ item, type });
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (!itemToDelete) return;
    
    const { item, type } = itemToDelete;
    try {
      if (type === 'image') {
        await api.delete(`/playground/images/${item.id}`);
        setGeneratedImages(prevImages => prevImages.filter(img => img.id !== item.id));
        
        // If the deleted image is the selected image, close the modal
        if (selectedImage && selectedImage.id === item.id) {
          setSelectedImage(null);
        }
        
        // Update community showcase if the deleted item is there
        setCommunityContent(prevContent => 
          prevContent.filter(content => !(content.type === 'image' && content.id === item.id))
        );
      } else {
        await api.delete(`/playground/videos/${item.id}`);
        setGeneratedVideos(prevVideos => prevVideos.filter(vid => vid.id !== item.id));
        
        // If the deleted video is the selected video, close the modal
        if (selectedVideo && selectedVideo.id === item.id) {
          setSelectedVideo(null);
        }
        
        // Update community showcase if the deleted item is there
        setCommunityContent(prevContent => 
          prevContent.filter(content => !(content.type === 'video' && content.id === item.id))
        );
      }
      
      setSnackbarMessage(`${type === 'image' ? 'Image' : 'Video'} deleted successfully`);
      setSnackbarOpen(true);
    } catch (err) {
      console.error(`Error deleting ${type}:`, err);
      setError(err.response?.data?.detail || `Failed to delete ${type}`);
    } finally {
      setDeleteConfirmOpen(false);
      setItemToDelete(null);
    }
  };

  // Delete confirmation dialog
  const renderDeleteConfirmDialog = () => {
    if (!itemToDelete) return null;
    
    const { type } = itemToDelete;
    const title = type === 'image' ? 'Delete Image' : 'Delete Video';
    const content = `Are you sure you want to delete this ${type}? This action cannot be undone.`;
    
    return (
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" startIcon={<DeleteIcon />}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // ---------------------------
  // Sharing Functions
  // ---------------------------
  const handleShareClick = (event, item, type) => {
    event.stopPropagation();
    setShareAnchorEl(event.currentTarget);
    setShareItemType(type);
    setShareItemData(item);
  };

  const handleShareClose = () => {
    setShareAnchorEl(null);
  };

  const handleShareViaLink = () => {
    if (shareItemData) {
      navigator.clipboard.writeText(shareItemData.result);
      setSnackbarMessage('Link copied to clipboard!');
      setSnackbarOpen(true);
      handleShareClose();
    }
  };

  const handleShareToSocial = async (platform) => {
    if (!shareItemData) return;
    
    const url = shareItemData.result;
    const title = `Check out this ${shareItemType} generated with AI`;
    const text = shareItemData.prompt || 'AI-generated content';
    
    try {
      // Try using Web Share API if available
      if (navigator.share) {
        try {
          await navigator.share({
            title: title,
            text: text,
            url: url,
          });
          setSnackbarMessage('Content shared successfully!');
          setSnackbarOpen(true);
        } catch (error) {
          // User cancelled or sharing failed
          if (error.name !== 'AbortError') {
            console.error('Error sharing content:', error);
            setSnackbarMessage('Sharing failed. You can copy the link instead.');
            setSnackbarOpen(true);
          }
          // AbortError means user cancelled - no need to show error
        }
      } else {
        // Fallback: Open in new window with platform-specific URL
        let shareUrl;
        
        switch(platform) {
          case 'facebook':
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
            break;
          case 'twitter':
            shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
            break;
          case 'linkedin':
            shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
            break;
          default:
            // Just copy to clipboard as fallback
            navigator.clipboard.writeText(url);
            setSnackbarMessage('Link copied to clipboard!');
            setSnackbarOpen(true);
            handleShareClose();
            return;
        }
        
        window.open(shareUrl, '_blank', 'width=600,height=400');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // Fallback to copying to clipboard
      navigator.clipboard.writeText(url);
      setSnackbarMessage('Link copied to clipboard!');
      setSnackbarOpen(true);
    } finally {
      handleShareClose();
    }
  };

  // ---------------------------
  // Generation Handlers (with Snackbar notification)
  // ---------------------------
  const handleGenerateImage = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt');
      return;
    }
    setError('');
    setIsLoading(true);
    try {
      await api.post('/playground/generate-image', {
        model: imageModel,
        prompt: prompt.trim(),
        aspect_ratio: imageSize,
        image_format: imageFormat, 
        seed: seed || undefined
      });
      setSnackbarMessage('Image generation started.');
      setSnackbarOpen(true);
      fetchUserImages();
    } catch (err) {
      console.error('Error generating images:', err);
      setError(err.response?.data?.detail || 'Failed to generate images');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateVideo = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt');
      return;
    }
    setError('');
    setIsLoading(true);
    try {
      await api.post('/playground/generate-video', {
        model: videoModel,
        prompt: prompt.trim(),
        quality: videoResolution,
        fps: parseInt(videoFps)
      });
      setSnackbarMessage('Video generation started.');
      setSnackbarOpen(true);
      fetchUserVideos();
    } catch (err) {
      console.error('Error generating video:', err);
      setError(err.response?.data?.detail || 'Failed to generate video');
    } finally {
      setIsLoading(false);
    }
  };

  // ---------------------------
  // Copy Prompt Handler
  // ---------------------------
  const handleCopyPrompt = (promptText) => {
    navigator.clipboard.writeText(promptText);
    setSnackbarMessage('Prompt copied to clipboard!');
    setSnackbarOpen(true);
  };

  // ---------------------------
  // Modal Handlers for Full View & Download
  // ---------------------------
  const handleImageClick = (image) => {
    setSelectedImage(image);
    // Reset dimensions when selecting a new image
    setImageDimensions({ width: 0, height: 0 });
  };

  // Function to handle image load in modal and get its natural dimensions
  const handleImageLoad = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = imageRef.current;
      setImageDimensions({
        width: naturalWidth,
        height: naturalHeight
      });
      
      // Calculate optimal container height based on image aspect ratio and window size
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      
      // For portrait images (taller than wide)
      if (naturalHeight > naturalWidth) {
        // Use more vertical space (90vh) for portrait images
        setImageContainerHeight('90vh');
      } else {
        // For landscape or square images, calculate based on aspect ratio
        const imageAspectRatio = naturalWidth / naturalHeight;
        const viewportAspectRatio = viewportWidth / viewportHeight;
        
        // If image is wider relative to viewport, adjust container height
        if (imageAspectRatio > viewportAspectRatio) {
          setImageContainerHeight('75vh'); // Lower height for very wide images
        } else {
          setImageContainerHeight('85vh'); // More height for less wide images
        }
      }
    }
  };

  // Add window resize handler to adjust container dynamically
  useEffect(() => {
    const handleResize = () => {
      // Recalculate if there's an image loaded
      if (imageRef.current && selectedImage) {
        handleImageLoad();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selectedImage]);

  const handleDownloadImage = () => {
    const link = document.createElement('a');
    link.href = selectedImage.result;
    const format = selectedImage.image_format || selectedImage.format || 'webp';
    link.download = `generated_image.${format.toLowerCase()}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const handleDownloadVideo = () => {
    const link = document.createElement('a');
    link.href = selectedVideo.result;
    link.download = 'generated_video.mp4';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // ---------------------------
  // Code Dialog Functions
  // ---------------------------
  const handleShowCodeExamples = () => {
    setShowCodeDialog(true);
    const endpoint = tab === 0 ? '/playground/generate-image' : '/playground/generate-video';
    const params =
      tab === 0
        ? {
            model: 'glimmer-v1',
            prompt: prompt || 'Example prompt for generating an image',
            aspect_ratio: imageSize,
            image_format: imageFormat,
            seed: seed || undefined
          }
        : {
            model: 'wan-v1',
            prompt: prompt || 'Example prompt for generating a video',
            quality: videoResolution,
            fps: parseInt(videoFps)
          };
    setCodeExamples(generateContentStudioCodeSamples(endpoint, params));
  };

  const renderCodeDialog = () => {
    const getCurrentCode = () => {
      if (!codeExamples) return '';
      switch (codeTab) {
        case 0:
          return codeExamples.python;
        case 1:
          return codeExamples.javascript;
        case 2:
          return codeExamples.curl;
        default:
          return codeExamples.python;
      }
    };

    return (
      <Dialog
        open={showCodeDialog}
        onClose={() => setShowCodeDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            {tab === 0 ? 'Image Generation Code' : 'Video Generation Code'}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={() => setShowCodeDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Tabs value={codeTab} onChange={(_, newValue) => setCodeTab(newValue)} sx={{ mb: 2 }}>
            <Tab label="Python" />
            <Tab label="JavaScript" />
            <Tab label="cURL" />
          </Tabs>
          <Paper
            sx={{
              p: 2,
              bgcolor: 'grey.900',
              color: 'grey.100',
              borderRadius: 1,
              overflow: 'auto'
            }}
          >
            <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {getCurrentCode()}
            </pre>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(getCurrentCode());
              setSnackbarMessage('Code copied to clipboard!');
              setSnackbarOpen(true);
            }}
            startIcon={<ContentCopyIcon />}
            variant="contained"
            color="primary"
          >
            Copy to Clipboard
          </Button>
          <Button onClick={() => setShowCodeDialog(false)} variant="outlined">Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Share menu component with improved handling
  const renderShareMenu = () => {
    return (
      <Menu
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleShareClose}
        onClick={e => e.stopPropagation()} // Prevent clicks inside menu from bubbling
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        // Ensure menu closes when clicked outside or ESC pressed
        MenuListProps={{
          'aria-labelledby': 'share-button',
          dense: true
        }}
        // Proper transition handling
        TransitionProps={{
          onExited: () => {
            // Reset share item data when menu is fully closed
            setShareItemData(null);
            setShareItemType(null);
          }
        }}
      >
        <ListItem button onClick={handleShareViaLink}>
          <ListItemIcon>
            <LinkIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Copy Link" />
        </ListItem>
        <ListItem button onClick={() => handleShareToSocial('facebook')}>
          <ListItemIcon>
            <FacebookIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Facebook" />
        </ListItem>
        <ListItem button onClick={() => handleShareToSocial('twitter')}>
          <ListItemIcon>
            <TwitterIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Twitter" />
        </ListItem>
        <ListItem button onClick={() => handleShareToSocial('linkedin')}>
          <ListItemIcon>
            <LinkedInIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="LinkedIn" />
        </ListItem>
      </Menu>
    );
  };

  // ---------------------------
  // Helper: Calculate Progress %
  // ---------------------------
  const calculateProgress = (createdAt, expectedDuration) => {
    const elapsed = currentTime - new Date(createdAt).getTime();
    let progress = (elapsed / expectedDuration) * 100;
    return Math.min(95, progress); // Cap at 95% until complete
  };

  // ---------------------------
  // Rendering Gallery (with in-progress indicator and progress bars)
  // ---------------------------
  const renderGeneratedImages = () => {
    if (generatedImages.length === 0) {
      return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            No images generated yet. Enter a prompt and click Create Image to generate images.
          </Typography>
        </Box>
      );
    }
    return (
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {generatedImages.map((image, index) => {
          const expectedDuration = 60000; // 1 minute for images
          const progress = image.status === 'completed' ? 100 : calculateProgress(image.created_at, expectedDuration);
          
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ContentCard>
                {image.status === 'completed' ? (
                  <Box sx={{ position: 'relative', height: 200 }}>
                    <StyledImage
                      src={image.result}
                      alt={`Generated image ${index + 1}`}
                      loading="lazy"
                      onClick={() => handleImageClick(image)}
                      sx={{ 
                        cursor: 'pointer',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <Box 
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        color: 'white',
                        p: 1,
                        backdropFilter: 'blur(2px)'
                      }}
                    >
                      <Typography variant="body2" noWrap>
                        {image.prompt ? (image.prompt.substring(0, 60) + (image.prompt.length > 60 ? '...' : '')) : 'No prompt available'}
                      </Typography>
                    </Box>
                    <Chip
                      size="small"
                      label={image.status}
                      color={image.status === 'completed' ? 'success' : 'warning'}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        fontWeight: 500
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: 200,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: 2,
                      backgroundColor: 'action.hover'
                    }}
                  >
                    <CircularProgress variant="determinate" value={progress} size={60} thickness={4} />
                    <Typography variant="caption" sx={{ mt: 2, fontWeight: 500 }}>
                      {Math.round(progress)}% Complete
                    </Typography>
                  </Box>
                )}
                <CardContent sx={{ p: 2, flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography variant="caption" color="text.secondary">
                      {new Date(image.created_at).toLocaleString()}
                    </Typography>
                    
                    <Box sx={{ display: 'flex' }}>
                      {image.status === 'completed' && (
                        <IconButton 
                          size="small"
                          onClick={(e) => handleShareClick(e, image, 'image')}
                          color="primary"
                        >
                          <ShareIcon fontSize="small" />
                        </IconButton>
                      )}
                      <IconButton 
                        size="small"
                        onClick={(e) => handleDeleteClick(e, image, 'image')}
                        color="error"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                  <Button
                    size="small"
                    variant="text"
                    startIcon={<ContentCopyIcon fontSize="small" />}
                    onClick={() => handleCopyPrompt(image.prompt)}
                    sx={{ mt: 1, p: 0 }}
                  >
                    Copy prompt
                  </Button>
                </CardContent>
              </ContentCard>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderGeneratedVideos = () => {
    if (generatedVideos.length === 0) {
      return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            No videos generated yet. Enter a prompt and click Create Video to generate videos.
          </Typography>
        </Box>
      );
    }
    return (
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {generatedVideos.map((video, index) => {
          let expectedDuration = 480000; // default medium: 8 minutes
          if (video.quality === 'low') expectedDuration = 360000;
          else if (video.quality === 'high') expectedDuration = 600000;
          const progress = video.status === 'completed' ? 100 : calculateProgress(video.created_at, expectedDuration);
          
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ContentCard>
                {video.status === 'completed' ? (
                  <Box sx={{ position: 'relative', height: 200 }}>
                    <StyledVideo
                      src={video.result}
                      autoPlay
                      muted
                      controls
                      loop
                      onClick={() => handleVideoClick(video)}
                      sx={{ 
                        cursor: 'pointer',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <Box 
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        color: 'white',
                        p: 1,
                        backdropFilter: 'blur(2px)'
                      }}
                    >
                      <Typography variant="body2" noWrap>
                        {video.prompt ? (video.prompt.substring(0, 60) + (video.prompt.length > 60 ? '...' : '')) : 'No prompt available'}
                      </Typography>
                    </Box>
                    <Chip
                      size="small"
                      label={video.status}
                      color={video.status === 'completed' ? 'success' : 'warning'}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        fontWeight: 500
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: 200,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: 2,
                      backgroundColor: 'action.hover'
                    }}
                  >
                    <CircularProgress variant="determinate" value={progress} size={60} thickness={4} />
                    <Typography variant="caption" sx={{ mt: 2, fontWeight: 500 }}>
                      {Math.round(progress)}% Complete
                    </Typography>
                  </Box>
                )}
                <CardContent sx={{ p: 2, flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography variant="caption" color="text.secondary">
                      {new Date(video.created_at).toLocaleString()}
                    </Typography>
                    
                    <Box sx={{ display: 'flex' }}>
                      {video.status === 'completed' && (
                        <IconButton 
                          size="small"
                          onClick={(e) => handleShareClick(e, video, 'video')}
                          color="primary"
                        >
                          <ShareIcon fontSize="small" />
                        </IconButton>
                      )}
                      <IconButton 
                        size="small"
                        onClick={(e) => handleDeleteClick(e, video, 'video')}
                        color="error"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                  <Button
                    size="small"
                    variant="text"
                    startIcon={<ContentCopyIcon fontSize="small" />}
                    onClick={() => handleCopyPrompt(video.prompt)}
                    sx={{ mt: 1, p: 0 }}
                  >
                    Copy prompt
                  </Button>
                </CardContent>
              </ContentCard>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  // Render community feed with both images and videos (with updated styling)
  // Note: No delete buttons in community showcase as requested
  const renderCommunityFeed = () => {
    if (communityContent.length === 0 && !loadingMoreCommunity) {
      return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            No community content available yet.
          </Typography>
        </Box>
      );
    }
    
    return (
      <Box 
        ref={communityContainerRef} 
        sx={{ 
          height: 'calc(100vh - 280px)',
          overflowY: 'auto',
          pr: 2,
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '-ms-overflow-style': 'none',
          'scrollbarWidth': 'none'
        }}
      >
        <Grid container spacing={1}>
          {communityContent.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`community-${index}`}>
              <ContentCard>
                <Box sx={{ position: 'relative', height: 200 }}>
                  {item.type === 'image' ? (
                    <StyledImage
                      src={item.result}
                      alt={`Community image ${index + 1}`}
                      loading="lazy"
                      onClick={() => handleImageClick(item)}
                      sx={{ 
                        cursor: 'pointer',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  ) : (
                    <StyledVideo
                      src={item.result}
                      autoPlay
                      muted
                      controls
                      loop
                      onClick={() => handleVideoClick(item)}
                      sx={{ 
                        cursor: 'pointer',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  )}
                  <Box sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    p: 1.5,
                    backdropFilter: 'blur(2px)'
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TypeIndicator contentType={item.type} />
                      <Typography variant="body2" noWrap>
                        {item.prompt ? (item.prompt.substring(0, 60) + (item.prompt.length > 60 ? '...' : '')) : 'No prompt available'}
                      </Typography>
                    </Box>
                  </Box>
                  
                </Box>
              </ContentCard>
            </Grid>
          ))}
        </Grid>
        
        {loadingMoreCommunity && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
            <CircularProgress />
          </Box>
        )}
        
        {!hasMoreCommunity && communityContent.length > 0 && (
          <Box sx={{ textAlign: 'center', py: 3 }}>
            <Typography variant="body2" color="text.secondary">
              You've reached the end
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  // ---------------------------
  // Main Render
  // ---------------------------
  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', position: 'relative', overflow: 'hidden' }}>
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          transition: 'margin 0.3s ease',
          height: '100%',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '-ms-overflow-style': 'none',
          'scrollbarWidth': 'none'
        }}
      >
        <Container maxWidth="xl" sx={{ py: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h4" fontWeight={600}>Studio</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'background.paper', px: 2, py: 1, borderRadius: 1 }}>
                <Typography variant="body2" color="text.secondary" sx={{ mr: 1, fontWeight: 500 }}>
                  Credits:
                </Typography>
                <Typography variant="body1" fontWeight="600" color="primary.main">
                  {balanceLoading ? 'Loading...' : `$${(userBalance / 100).toFixed(2)} remaining`}
                </Typography>
              </Box>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                sx={{ ml: 2, fontWeight: 500, textTransform: 'none' }}
                href="/billing"
              >
                Top-up
              </Button>
            </Box>
          </Box>

          <StyledTabs 
            value={tab} 
            onChange={handleTabChange} 
            sx={{ mb: 3 }}
            variant="fullWidth"
          >
            <StyledTab icon={<ImageIcon />} iconPosition="start" label="IMAGE GENERATION" />
            <StyledTab icon={<VideocamIcon />} iconPosition="start" label="VIDEO GENERATION" />
            <StyledTab icon={<PublicIcon />} iconPosition="start" label="COMMUNITY" />
          </StyledTabs>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError('')}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          )}

          {/* Only show prompt input for image and video tabs */}
          {tab < 2 && (
            <Paper elevation={0} sx={{ mb: 3, p: 3, border: '1px solid', borderColor: 'divider' }}>
              <Typography variant="subtitle1" fontWeight={600} sx={{ mb: 2 }}>Prompt</Typography>
              
              {/* Integrated prompt field with embedded button */}
              <StyledInput
                fullWidth
                multiline
                rows={3}
                placeholder={
                  tab === 0
                    ? 'Describe the image you want to generate...'
                    : 'Describe the video you want to generate...'
                }
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onKeyPress={handleKeyPress}
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ alignSelf: 'flex-end', mb: 1 }}>
                      <CreateButton
                        variant="contained"
                        color="primary"
                        onClick={tab === 0 ? handleGenerateImage : handleGenerateVideo}
                        disabled={isLoading}
                        endIcon={isLoading ? <CircularProgress size={16} color="inherit" /> : null}
                      >
                        {isLoading ? 'Creating...' : (tab === 0 ? 'Create Image' : 'Create Video')}
                      </CreateButton>
                    </InputAdornment>
                  )
                }}
              />
              
              <Box sx={{ mt: 1 }}>
                <Typography variant="caption" color="text.secondary">
                  Press Enter to create, or Shift+Enter for a new line
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle2" gutterBottom fontWeight="500">
                  Example Prompts:
                </Typography>
                <Box
                  sx={{
                    maxHeight: '120px',
                    overflowY: 'auto',
                    mt: 1,
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    },
                    '-ms-overflow-style': 'none',
                    'scrollbarWidth': 'none'
                  }}
                >
                  {(tab === 0 ? imagePrompts : videoPrompts).map((examplePrompt, index) => (
                    <PromptChip
                      key={index}
                      label={examplePrompt.length > 60 ? examplePrompt.substring(0, 60) + '...' : examplePrompt}
                      onClick={() => handlePromptChipClick(examplePrompt)}
                      variant="outlined"
                      color="primary"
                      sx={{ mb: 0.5, mr: 0.5 }}
                    />
                  ))}
                </Box>
              </Box>
            </Paper>
          )}

          <Typography variant="h6" fontWeight={600} sx={{ mb: 2 }}>
            {tab === 0 ? 'Generated Images' : tab === 1 ? 'Generated Videos' : 'Community Showcase'}
          </Typography>
          
          {tab === 0 ? renderGeneratedImages() : 
           tab === 1 ? renderGeneratedVideos() : 
           renderCommunityFeed()}
        </Container>
      </Box>

      <StyledSidebar sx={{ display: tab === 2 ? 'none' : 'block' }}>
        <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
          {tab === 0 ? 'Image Settings' : 'Video Settings'}
        </Typography>
        
        {tab === 0 ? (
          <React.Fragment>
            <SidebarSection>
              <Typography variant="subtitle2" fontWeight={500} gutterBottom>
                Aspect Ratio
              </Typography>
              <FormControl fullWidth variant="outlined" size="small">
                <StyledSelect value={imageSize} onChange={(e) => setImageSize(e.target.value)}>
                  <MenuItem value="1:1">Square (1:1)</MenuItem>
                  <MenuItem value="16:9">Landscape (16:9)</MenuItem>
                  <MenuItem value="9:16">Portrait (9:16)</MenuItem>
                </StyledSelect>
              </FormControl>
            </SidebarSection>
            
            <SidebarSection>
              <Typography variant="subtitle2" fontWeight={500} gutterBottom>
                Image Format
              </Typography>
              <FormControl fullWidth variant="outlined" size="small">
                <StyledSelect value={imageFormat} onChange={(e) => setImageFormat(e.target.value)}>
                  <MenuItem value="WEBP">WEBP</MenuItem>
                  <MenuItem value="PNG">PNG</MenuItem>
                  <MenuItem value="JPEG">JPEG</MenuItem>
                </StyledSelect>
              </FormControl>
            </SidebarSection>
            
            <SidebarSection>
              <Typography variant="subtitle2" fontWeight={500} gutterBottom>
                Seed (Optional)
              </Typography>
              <StyledInput
                fullWidth
                type="number"
                size="small"
                value={seed || ''}
                onChange={(e) => setSeed(e.target.value ? parseInt(e.target.value) : null)}
                placeholder="Enter seed value"
              />
            </SidebarSection>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SidebarSection>
              <Typography variant="subtitle2" fontWeight={500} gutterBottom>
                Quality
              </Typography>
              <FormControl fullWidth variant="outlined" size="small">
                <StyledSelect value={videoResolution} onChange={(e) => setVideoResolution(e.target.value)}>
                  <MenuItem value="low">Low</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                </StyledSelect>
              </FormControl>
            </SidebarSection>
            
            <SidebarSection>
              <Typography variant="subtitle2" fontWeight={500} gutterBottom>
                Frames Per Second
              </Typography>
              <StyledInput
                fullWidth
                type="number"
                size="small"
                value={videoFps}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (value >= 1 && value <= 60) setVideoFps(value);
                }}
                placeholder="Enter FPS (1-60)"
              />
            </SidebarSection>
          </React.Fragment>
        )}
        
        {tab < 2 && (
          <SidebarButton
            variant="contained"
            color="primary"
            startIcon={<CodeIcon />}
            onClick={handleShowCodeExamples}
            disabled={isLoading}
            sx={{ mt: 2 }}
          >
            Get Code
          </SidebarButton>
        )}
      </StyledSidebar>

      {/* Share Menu */}
      {renderShareMenu()}

      {/* Delete Confirmation Dialog */}
      {renderDeleteConfirmDialog()}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />

      {renderCodeDialog()}

      <Dialog 
        open={Boolean(selectedImage)} 
        onClose={() => setSelectedImage(null)} 
        maxWidth="lg" 
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: '95vh',  // Maximum height constraint
            maxWidth: '95vw',   // Maximum width constraint
            height: imageContainerHeight, // Dynamic height based on image
            width: '100%',
            margin: '0',        // Remove default margins
            borderRadius: 1     // Rounded corners
          }
        }}
      >
        <DialogContent 
          sx={{ 
            textAlign: 'center', 
            p: 0, 
            position: 'relative',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.paper',
            overflow: 'hidden' // Prevent scrollbars inside the content
          }}
        >
          {selectedImage && (
            <Box 
              sx={{ 
                position: 'relative',
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
              }}
            >
              <img
                ref={imageRef}
                src={selectedImage.result}
                alt="Full view"
                onLoad={handleImageLoad}
                style={{ 
                  maxWidth: '100%', 
                  maxHeight: 'calc(100% - 60px)', // Accounting for the footer
                  objectFit: 'contain',
                  display: 'block'
                }}
              />
              <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                p: 2,
                backdropFilter: 'blur(4px)'
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="caption" color="white">
                    {selectedImage.created_at ? new Date(selectedImage.created_at).toLocaleString() : 'No date available'}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      size="small"
                      variant="text"
                      startIcon={<ContentCopyIcon fontSize="small" />}
                      onClick={() => handleCopyPrompt(selectedImage.prompt)}
                      sx={{ color: 'white' }}
                    >
                      Copy prompt
                    </Button>
                    <Button
                      size="small"
                      variant="text"
                      startIcon={<ShareIcon fontSize="small" />}
                      onClick={(e) => handleShareClick(e, selectedImage, 'image')}
                      sx={{ color: 'white' }}
                    >
                      Share
                    </Button>
                  </Box>
                </Box>
                <Typography variant="body2">
                  {selectedImage.prompt || 'No prompt available'}
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ 
          backgroundColor: 'background.paper',
          p: 2,
          borderTop: '1px solid',
          borderColor: 'divider'
        }}>
          <Button variant="contained" color="primary" startIcon={<DownloadIcon />} onClick={handleDownloadImage}>
            Download
          </Button>
          {/* Only show delete button if the image belongs to the user (if it has a type property, it's from community) */}
          {!selectedImage?.type && (
            <Button 
              variant="contained" 
              color="error" 
              startIcon={<DeleteIcon />} 
              onClick={(e) => {
                setSelectedImage(null);
                handleDeleteClick(e, selectedImage, 'image');
              }}
            >
              Delete
            </Button>
          )}
          <Button variant="outlined" onClick={() => setSelectedImage(null)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={Boolean(selectedVideo)} onClose={() => setSelectedVideo(null)} maxWidth="md" fullWidth>
        <DialogContent sx={{ textAlign: 'center', p: 0, position: 'relative', height: '80vh' }}>
          {selectedVideo && (
            <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
              <video
                src={selectedVideo.result}
                autoPlay
                muted
                controls
                loop
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover', 
                  display: 'block' 
                }}
              />
              <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                p: 2,
                backdropFilter: 'blur(4px)'
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="caption" color="white">
                    {selectedVideo.created_at ? new Date(selectedVideo.created_at).toLocaleString() : 'No date available'}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      size="small"
                      variant="text"
                      startIcon={<ContentCopyIcon fontSize="small" />}
                      onClick={() => handleCopyPrompt(selectedVideo.prompt)}
                      sx={{ color: 'white' }}
                    >
                      Copy prompt
                    </Button>
                    <Button
                      size="small"
                      variant="text"
                      startIcon={<ShareIcon fontSize="small" />}
                      onClick={(e) => handleShareClick(e, selectedVideo, 'video')}
                      sx={{ color: 'white' }}
                    >
                      Share
                    </Button>
                  </Box>
                </Box>
                <Typography variant="body2">
                  {selectedVideo.prompt || 'No prompt available'}
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: 'background.paper' }}>
          <Button variant="contained" color="primary" startIcon={<DownloadIcon />} onClick={handleDownloadVideo}>
            Download
          </Button>
          {/* Only show delete button if the video belongs to the user (if it has a type property, it's from community) */}
          {!selectedVideo?.type && (
            <Button 
              variant="contained" 
              color="error" 
              startIcon={<DeleteIcon />} 
              onClick={(e) => {
                setSelectedVideo(null);
                handleDeleteClick(e, selectedVideo, 'video');
              }}
            >
              Delete
            </Button>
          )}
          <Button variant="outlined" onClick={() => setSelectedVideo(null)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContentStudio;