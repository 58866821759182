// frontend/src/utils/contentStudioCodeSamples.js

/**
 * Code samples for making API calls to the Content Studio endpoint
 * These samples will be used in the CodeDialog component in ContentStudio.js
 */

// Generate examples for different languages
export const generateContentStudioCodeSamples = (endpoint, params) => {
  // Set the API base URL based on the endpoint type
  const apiBaseUrl = endpoint.includes('image') 
    ? 'https://api.brilliantai.co/images/generate'
    : 'https://api.brilliantai.co/videos/generate';
  
  // Full endpoint is now just the API base URL as it already includes the full path
  const fullEndpoint = apiBaseUrl;
  
  // Format the parameters in a readable way
  const formatParams = (obj, indent = 0) => {
    const spaces = '  '.repeat(indent);
    return Object.entries(obj)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          return `${spaces}"${key}": ${formatParams(value, indent + 1)}`;
        }
        if (typeof value === 'string') {
          return `${spaces}"${key}": "${value}"`;
        }
        return `${spaces}"${key}": ${value}`;
      })
      .join(',\n');
  };
  
  const formattedParams = formatParams(params, 1);
  
  // Extract key parameters for the Python example
  const isImageEndpoint = endpoint.includes('image');
  const model = params.model || (isImageEndpoint ? "glimmer-v1" : "wan-v1");
  const prompt = params.prompt || "your prompt here";
  
  // Python code using the BrilliantAI client library
  const pythonCode = `from brilliantai import BrilliantAI
import os

# Get API key from environment or set it directly
api_key = os.environ.get("BRILLIANTAI_API_KEY", "YOUR_API_KEY_HERE")

# Initialize the client
client = BrilliantAI(api_key=api_key)

# Generate ${isImageEndpoint ? 'an image' : 'a video'}
print("Generating ${isImageEndpoint ? 'image' : 'video'}...")
${isImageEndpoint ? 
  `image = client.generate_image(
    model="${model}",
    prompt="${prompt}",
    ${params.aspect_ratio ? `aspect_ratio=BrilliantAI.AspectRatio.${params.aspect_ratio.toUpperCase()},` : ''}
    ${params.image_format ? `image_format=BrilliantAI.ImageFormat.${params.image_format.toUpperCase()},` : ''}
    ${params.seed ? `seed=${params.seed}` : 'seed=42'}
)
print(f"Generated image: {image.url}")
# To save the image
image.save("generated_image")  # Saves as "generated_image.png" or .jpg based on format` : 
  `video = client.generate_video(
    model="${model}",
    prompt="${prompt}",
    ${params.quality ? `quality=BrilliantAI.VideoQuality.${params.quality.toUpperCase()},` : ''}
    ${params.fps ? `fps=${params.fps}` : 'fps=24'}
)
print(f"Generated video: {video.url}")
# To save the video
video.save("generated_video")  # Saves as "generated_video.mp4"`}`;

  // JavaScript code using fetch
  const jsCode = `// Define the API endpoint
const url = "${fullEndpoint}";

// Set your API key
const apiKey = "YOUR_API_KEY_HERE";

// Define the request body
const payload = {
${formattedParams}
};

// Make the API request
fetch(url, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + apiKey
  },
  body: JSON.stringify(payload)
})
  .then(response => {
    if (!response.ok) {
      throw new Error('HTTP error! Status: ' + response.status);
    }
    return response.json();
  })
  .then(data => {
    console.log('Success!');
    ${endpoint.includes('image') ? 
      'data.images.forEach(image => {\n    console.log("Generated image URL: " + image.url);\n  });' : 
      'console.log("Generated video URL: " + data.video_url);'}
  })
  .catch(error => {
    console.error('Error:', error);
  });`;

  // cURL command
  const curlCommand = `curl -X POST "${fullEndpoint}" \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer YOUR_API_KEY_HERE" \\
  -d '{
${formattedParams}
}'`;

  return {
    python: pythonCode,
    javascript: jsCode,
    curl: curlCommand,
  };
};