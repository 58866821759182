import React, { useState, useRef, useEffect } from 'react';
import { Container, Typography, Button, Grid, Box, Link, Tabs, Tab, useTheme, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SecurityIcon from '@mui/icons-material/Security';
import LowCostIcon from '@mui/icons-material/AttachMoney';
import ModelsIcon from '@mui/icons-material/Memory';
import APIIcon from '@mui/icons-material/Api';
import DocsIcon from '@mui/icons-material/Description';
import SupportIcon from '@mui/icons-material/SupportAgent';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MovieIcon from '@mui/icons-material/Movie';
import ImageIcon from '@mui/icons-material/Image';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import AnimatedBackground from '../components/AnimatedBackground';
import SEOHelmet from '../components/SEOHelmet';

const VideoBackgroundLoop = ({ videoFolderPath = '/studio-showcase/' }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);
  
  const videoFiles = [
    'https://brilliantai-files.b-cdn.net/girl-in-rain.mp4',
    'https://brilliantai-files.b-cdn.net/flying-cat.mp4',
    'https://brilliantai-files.b-cdn.net/black-man.mp4',
    'https://brilliantai-files.b-cdn.net/girl-in-room.mp4',
    'https://brilliantai-files.b-cdn.net/jellyfish.mp4',
    'https://brilliantai-files.b-cdn.net/skating.mp4',
    'https://brilliantai-files.b-cdn.net/horse.mp4',
    'https://brilliantai-files.b-cdn.net/diver.mp4',
  ];

  useEffect(() => {
    const videoElement = videoRef.current;
    
    const handleVideoEnd = () => {
      const nextIndex = (currentVideoIndex + 1) % videoFiles.length;
      setCurrentVideoIndex(nextIndex);
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd);
      videoElement.play().catch(error => console.error('Video autoplay failed:', error));
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, [currentVideoIndex, videoFiles.length]);

  return (
    <Box
      component="video"
      ref={videoRef}
      autoPlay
      muted
      playsInline
      src={videoFiles[currentVideoIndex]}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 0,
        opacity: { xs: 0.2, sm: 0.3 },
        filter: 'brightness(0.6) contrast(1.1)',
        transition: 'opacity 0.5s ease-in-out',
      }}
    />
  );
};

function Home() {
  const [selectedLanguage, setSelectedLanguage] = useState('nodejs');
  const [activeSection, setActiveSection] = useState(0);
  const sectionsContainerRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Pricing data
  const pricing = {
    imageGeneration: '$0.07 per image',
    videoGeneration: {
      lowRes: '$0.7 per video',
      mediumRes: '$0.9 per video',
      highRes: '$1.2 per video'
    }
  };

  const sections = [
    {
      id: "studio",
      title: "Introducing Studio",
      description: "Create stunning images and videos with our powerful AI APIs and intuitive studio interface."
    },
    {
      id: "intro",
      title: "Frontier AI Models and Agents for Developers",
      description: "Build modern AI apps with state-of-the-art LLMs and agents."
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const container = sectionsContainerRef.current;
      if (container) {
        const scrollLeft = container.scrollLeft;
        const sectionWidth = container.offsetWidth;
        const currentSection = Math.round(scrollLeft / sectionWidth);
        setActiveSection(currentSection);
      }
    };

    const container = sectionsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const codeExamples = {
    nodejs: {
      code: `import OpenAI from "openai";
const openai = new OpenAI({
    baseURL: "https://api.brilliantai.co",
    apiKey: "Your-API-Key"
});
const completion = await openai.chat.completions.create({
    model: "mistral-nemo",
    messages: [
        {"role": "user", "content": "write a haiku about ai"}
    ]
});`,
      language: 'javascript'
    },
    python: {
      code: `from openai import OpenAI
client = OpenAI(
    base_url="https://api.brilliantai.co",
    api_key="Your-API-Key"
)
completion = client.chat.completions.create(
    model="mistral-nemo",
    messages=[
        {"role": "user", "content": "write a haiku about ai"}
    ]
)`,
      language: 'python'
    },
    curl: {
      code: `curl https://api.brilliantai.co/chat/completions \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer $OPENAI_API_KEY" \\
-d '{
    "model": "mistral-nemo",
    "messages": [
        {"role": "user", "content": "write a haiku about ai"}
    ]
}'`,
      language: 'bash'
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedLanguage(newValue);
  };

  const handleSectionChange = (index) => {
    const container = sectionsContainerRef.current;
    if (container) {
      container.scrollTo({
        left: index * container.offsetWidth,
        behavior: 'smooth',
      });
      setActiveSection(index);
    }
  };

  const scrollToServices = () => {
    const servicesSection = document.getElementById('ai-services');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Homepage structured data
  const homeStructuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Brilliant AI",
    "url": "https://brilliantai.co",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://brilliantai.co/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    },
    "description": "The dev platform for all your Gen AI needs. Access top-performing language models, image generation, video creation, and more.",
    "offers": {
      "@type": "AggregateOffer",
      "priceCurrency": "USD",
      "lowPrice": "0",
      "highPrice": "1.2",
      "offerCount": "4"
    }
  };

  return (
    <Box sx={{ 
      overflowX: 'hidden', 
      overflowY: 'auto', 
      fontFamily: "'Inter', sans-serif",
      width: '100%',
      maxWidth: '100vw',
      position: 'relative'
    }}>
      <SEOHelmet 
        title="Home"
        description="Brilliant AI - The dev platform for all your Gen AI needs. Access top-performing language models, image generation, video creation, and more."
        keywords="AI, artificial intelligence, LLM, language models, API, image generation, video generation, developers, Brilliant AI"
        image="/llamacloud-cover.png"
        structuredData={homeStructuredData}
      />
      {/* Horizontal Sections */}
      <Box sx={{ position: 'relative', height: '100vh', maxWidth: '100vw', overflowX: 'hidden' }}>
        <Box 
          ref={sectionsContainerRef}
          sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            overflowX: { xs: 'hidden', md: 'auto' },
            scrollSnapType: { md: 'x mandatory' },
            scrollBehavior: 'smooth',
            height: '100%',
            width: '100%',
            maxWidth: '100vw',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {sections.map((section, index) => (
            <Box
              key={section.id}
              sx={{
                width: { xs: '100%', md: '100%' },
                maxWidth: '100vw',
                height: { xs: '50vh', md: '100vh' },
                flex: { md: '0 0 auto' },
                scrollSnapAlign: { md: 'start' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              {section.id === "studio" ? (
                <VideoBackgroundLoop videoFolderPath="/studio-showcase/" />
              ) : (
                <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0 }}>
                  <AnimatedBackground 
                    density={isMobile ? 'low' : isTablet ? 'medium' : 'high'} 
                  />
                </Box>
              )}
              <Container sx={{ position: 'relative', zIndex: 1, px: { xs: 2, sm: 3 } }}>
                {section.id === "intro" ? (
                  <Box sx={{ textAlign: 'center', py: { xs: 4, md: 8 } }}>
                    <Typography 
                      variant="h1" 
                      sx={{ 
                        color: '#52b788',
                        fontSize: { xs: '1.8rem', sm: '3rem', md: '5rem' },
                        fontWeight: 900,
                        mb: { xs: 2, md: 4 },
                        lineHeight: 1.1,
                      }}
                    >
                      {section.title}
                    </Typography>
                    <Typography 
                      variant="h5" 
                      sx={{ 
                        color: 'rgba(255, 255, 255, 0.9)',
                        mb: { xs: 3, md: 6 },
                        fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
                        maxWidth: { xs: '100%', md: '700px' },
                        mx: 'auto',
                      }}
                    >
                      {section.description}
                    </Typography>
                    <Button
                      variant="contained"
                      component={RouterLink}
                      to="/signup"
                      sx={{ 
                        bgcolor: '#52b788',
                        px: { xs: 4, md: 6 },
                        py: { xs: 1, md: 1.5 },
                        fontSize: { xs: '0.9rem', md: '1.1rem' },
                        borderRadius: 10,
                        '&:hover': {
                          bgcolor: '#40916c',
                          transform: 'translateY(-2px)',
                        }
                      }}
                    >
                      Get Started
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ py: { xs: 4, md: 10 } }}>
                    <Typography 
                      variant="h2" 
                      sx={{ 
                        fontWeight: 900, 
                        color: '#52b788',
                        mb: 3,
                        fontSize: { xs: '1.5rem', sm: '2rem', md: '3.5rem' },
                      }}
                    >
                      {section.title}
                    </Typography>
                    <Typography 
                      variant="h5" 
                      sx={{ 
                        mb: 5, 
                        color: 'rgba(255, 255, 255, 0.9)',
                        fontSize: { xs: '1rem', md: '1.5rem' },
                        maxWidth: { xs: '100%', md: '800px' },
                      }}
                    >
                      {section.description}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 4 }}>
                      <Button 
                        variant="contained" 
                        component={RouterLink}
                        to="/studio"
                        startIcon={<AutoAwesomeIcon />}
                        sx={{ 
                          bgcolor: '#52b788',
                          py: 1, 
                          px: 4,
                          borderRadius: 10,
                          fontSize: { xs: '0.9rem', md: '1rem' },
                          '&:hover': { bgcolor: '#40916c' }
                        }}
                      >
                        Try Studio
                      </Button>
                      <Button 
                        variant="outlined" 
                        startIcon={<APIIcon />}
                        onClick={() => window.open('https://docs.brilliantai.co', '_blank')}
                        sx={{ 
                          borderColor: '#52b788',
                          color: '#52b788',
                          py: 1, 
                          px: 4,
                          borderRadius: 10,
                          fontSize: { xs: '0.9rem', md: '1rem' },
                          '&:hover': { borderColor: '#40916c' }
                        }}
                      >
                        Use API
                      </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1, bgcolor: 'rgba(82, 183, 136, 0.15)', borderRadius: 2 }}>
                        <ImageIcon sx={{ color: '#52b788', fontSize: { xs: 18, md: 20 } }} />
                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.9)', fontSize: { xs: '0.8rem', md: '0.9rem' }, ml: 1 }}>
                          Image Generation
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1, bgcolor: 'rgba(82, 183, 136, 0.15)', borderRadius: 2 }}>
                        <MovieIcon sx={{ color: '#52b788', fontSize: { xs: 18, md: 20 } }} />
                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.9)', fontSize: { xs: '0.8rem', md: '0.9rem' }, ml: 1 }}>
                          Video Creation
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Container>
            </Box>
          ))}
        </Box>
        {/* Navigation Dots */}
        <Box
          sx={{
            position: 'absolute',
            bottom: { xs: 10, md: 30 },
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            gap: 1.5,
            zIndex: 2,
          }}
        >
          {sections.map((_, index) => (
            <Box
              key={index}
              onClick={() => handleSectionChange(index)}
              sx={{
                width: { xs: 8, md: 10 },
                height: { xs: 8, md: 10 },
                borderRadius: '50%',
                backgroundColor: activeSection === index ? '#52b788' : 'rgba(255, 255, 255, 0.4)',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
              }}
            />
          ))}
        </Box>
      </Box>

      {/* Features Section */}
      <Box id="ai-services" sx={{ py: { xs: 6, md: 12 }, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center" 
            sx={{ 
              color: '#52b788', 
              fontWeight: 800, 
              mb: { xs: 4, md: 8 },
              fontSize: { xs: '1.8rem', md: '2.5rem' },
            }}
          >
            Our AI Services
          </Typography>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            {[
              { title: 'LLM Inference', description: 'Access top performing language models', icon: <ModelsIcon /> },
              { title: 'Image generation', description: 'Generate stunning images easily', icon: <ImageIcon />},
              {title: 'Video generation', description: 'Create high-quality videos', icon: <MovieIcon />},
              { title: 'Agents (Coming Soon)', description: 'Create powerful AI agents', icon: <ModelsIcon /> },
              { title: 'Embeddings', description: 'Build RAG applications', icon: <APIIcon /> }
            ].map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box sx={{
                  p: { xs: 2, md: 4 },
                  bgcolor: 'rgba(30, 41, 59, 0.8)',
                  borderRadius: 3,
                  height: '100%',
                  '&:hover': {
                    transform: { md: 'translateY(-8px)' },
                  }
                }}>
                  {feature.icon}
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      mt: 2, 
                      mb: 1, 
                      color: '#52b788', 
                      fontSize: { xs: '1.1rem', md: '1.25rem' }
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'rgba(255, 255, 255, 0.8)', 
                      fontSize: { xs: '0.9rem', md: '1rem' }
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Pricing Section */}
      <Box sx={{ py: { xs: 6, md: 12 }, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center" 
            sx={{ 
              color: '#52b788', 
              fontWeight: 800, 
              mb: 3,
              fontSize: { xs: '1.8rem', md: '2.5rem' },
            }}
          >
            Simple, Transparent Pricing
          </Typography>
          <Typography 
            variant="h6" 
            align="center" 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.8)', 
              mb: { xs: 4, md: 8 },
              fontSize: { xs: '1rem', md: '1.25rem' },
            }}
          >
            Pay only for what you use.
          </Typography>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                p: { xs: 3, md: 4 }, 
                bgcolor: 'rgba(30, 41, 59, 0.8)', 
                borderRadius: 3,
                minHeight: {xs: 220, sm: 240, md: 280}
                }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: '#52b788', 
                    mb: 3, 
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  Text Generation
                </Typography>
                {[
                  'DeepSeek-R1: $0.0 per 1M tokens',
                  'Llama-3.3-70B: $0.8 per 1M tokens',
                  'Llama-3.2-8B: $0.2 per 1M tokens',
                  'Mistral-Nemo: $0.3 per 1M tokens'
                ].map((price, idx) => (
                  <Typography 
                    key={idx} 
                    variant="body1" 
                    sx={{ 
                      color: 'white', 
                      mb: 2,
                      fontSize: { xs: '0.9rem', md: '1rem' }
                    }}
                  >
                    {price}
                  </Typography>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ p: { xs: 3, md: 4 }, bgcolor: 'rgba(30, 41, 59, 0.8)', borderRadius: 3 }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: '#52b788', 
                    mb: 3, 
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  Image Generation
                </Typography>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: 'white', 
                    mb: 2,
                    fontSize: { xs: '0.9rem', md: '1rem' }
                  }}
                >
                  {pricing.imageGeneration}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ p: { xs: 3, md: 4 }, bgcolor: 'rgba(30, 41, 59, 0.8)', borderRadius: 3 }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: '#52b788', 
                    mb: 3, 
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  Video Generation
                </Typography>
                {[
                  `Low Mode: ${pricing.videoGeneration.lowRes}`,
                  `Medium Mode: ${pricing.videoGeneration.mediumRes}`,
                  `High Mode: ${pricing.videoGeneration.highRes}`
                ].map((price, idx) => (
                  <Typography 
                    key={idx} 
                    variant="body1" 
                    sx={{ 
                      color: 'white', 
                      mb: 2,
                      fontSize: { xs: '0.9rem', md: '1rem' }
                    }}
                  >
                    {price}
                  </Typography>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* LLM Inference Section */}
      <Box sx={{ py: { xs: 6, md: 12 }, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={{ xs: 3, md: 6 }} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography 
                variant="h3" 
                sx={{ 
                  color: '#52b788', 
                  fontWeight: 800,
                  fontSize: { xs: '1.8rem', md: '2.5rem' },
                  mb: 2
                }}
              >
                LLM Inference
              </Typography>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.8)', 
                  mb: 4,
                  fontSize: { xs: '1rem', md: '1.25rem' },
                }}
              >
                Unleash the power of advanced language models
              </Typography>
              {[
                'Cutting-edge multilingual LLMs',
                'Lightning-fast inference speeds',
                'Seamless OpenAI-compatible API',
                'Unbeatable value for performance'
              ].map((point, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <ModelsIcon sx={{ mr: 2, color: '#52b788', fontSize: { xs: 20, md: 24 } }} />
                  <Typography 
                    sx={{ 
                      color: 'white',
                      fontSize: { xs: '0.9rem', md: '1rem' }
                    }}
                  >
                    {point}
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography 
                variant="h4" 
                sx={{ 
                  color: '#52b788', 
                  mb: 4,
                  fontSize: { xs: '1.5rem', md: '2rem' },
                }}
              >
                Make Your First API Call
              </Typography>
              <Tabs
                value={selectedLanguage}
                onChange={handleTabChange}
                centered
                sx={{
                  mb: 3,
                  '& .MuiTab-root': {
                    fontSize: { xs: '0.9rem', md: '1rem' },
                  },
                }}
              >
                <Tab label="Node.js" value="nodejs" />
                <Tab label="Python" value="python" />
                <Tab label="cURL" value="curl" />
              </Tabs>
              <Box sx={{ bgcolor: '#1e293b', borderRadius: 3, overflow: 'hidden' }}>
                <SyntaxHighlighter
                  language={codeExamples[selectedLanguage].language}
                  style={oneDark}
                  customStyle={{ 
                    padding: { xs: '16px', md: '24px' },
                    fontSize: { xs: '0.8rem', md: '1rem' }
                  }}
                >
                  {codeExamples[selectedLanguage].code}
                </SyntaxHighlighter>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Agents Section */}
      <Box sx={{ py: { xs: 6, md: 12 }, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={{ xs: 3, md: 6 }} alignItems="center" direction={{ xs: 'column-reverse', md: 'row-reverse' }}>
            <Grid item xs={12} md={6}>
              <Typography 
                variant="h3" 
                sx={{ 
                  color: '#52b788', 
                  fontWeight: 800,
                  fontSize: { xs: '1.8rem', md: '2.5rem' },
                  mb: 2
                }}
              >
                AI Agents (Coming Soon)
              </Typography>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.8)', 
                  mb: 4,
                  fontSize: { xs: '1rem', md: '1.25rem' },
                }}
              >
                Supercharge your applications
              </Typography>
              {[
                'Custom functionalities with python',
                'Advanced reasoning capabilities',
                'Support for frontier models',
                'Built-in memory management',
                'Scalable and production-ready'
              ].map((point, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, p: 1 }}>
                  <APIIcon sx={{ mr: 2, color: '#52b788', fontSize: { xs: 20, md: 24 } }} />
                  <Typography 
                    sx={{ 
                      color: 'white',
                      fontSize: { xs: '0.9rem', md: '1rem' }
                    }}
                  >
                    {point}
                  </Typography>
                </Box>
              ))}
              <Button
                variant="contained"
                href="https://forms.office.com/e/k6PZBHhFnR"
                target="_blank"
                sx={{
                  mt: 4,
                  bgcolor: '#52b788',
                  px: { xs: 3, md: 5 },
                  py: 1,
                  fontSize: { xs: '0.9rem', md: '1rem' },
                  borderRadius: 10,
                }}
              >
                Join the Waitlist
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/agent.webp"
                alt="AI Agents"
                sx={{ width: '100%', borderRadius: 3 }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Consultancy Services Section */}
      <Box sx={{ py: { xs: 6, md: 12 }, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={{ xs: 3, md: 6 }} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography 
                variant="h3" 
                sx={{ 
                  color: '#52b788', 
                  fontWeight: 800,
                  fontSize: { xs: '1.8rem', md: '2.5rem' },
                  mb: 2
                }}
              >
                Consultancy
              </Typography>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.8)', 
                  mb: 4,
                  fontSize: { xs: '1rem', md: '1.25rem' },
                }}
              >
                Work with a team of expert AI scientists and engineers
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: 'white', 
                  mb: 4,
                  fontSize: { xs: '0.9rem', md: '1rem' },
                }}
              >
                
              </Typography>
              {[
                'Custom cloud deployments',
                'Secure on-prem solutions',
                'Specialized AI projects',
                'Enhanced video capabilities'
              ].map((point, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <SupportIcon sx={{ mr: 2, color: '#52b788', fontSize: { xs: 20, md: 24 } }} />
                  <Typography 
                    sx={{ 
                      color: 'white',
                      fontSize: { xs: '0.9rem', md: '1rem' }
                    }}
                  >
                    {point}
                  </Typography>
                </Box>
              ))}
              <Typography 
                variant="body1" 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.8)', 
                  mt: 4,
                  fontSize: { xs: '0.9rem', md: '1rem' },
                }}
              >
                Contact us: <Link href="mailto:hello@brilliantai.co" sx={{ color: '#52b788', textDecoration: 'none' }}>hello@brilliantai.co</Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/llama-in-a-cloud.png"
                alt="Consultancy Services"
                sx={{ width: '100%', borderRadius: 3 }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Benefits Section */}
      <Box sx={{ py: { xs: 6, md: 12 }, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center" 
            sx={{ 
              color: '#52b788', 
              fontWeight: 800, 
              mb: { xs: 4, md: 8 },
              fontSize: { xs: '1.8rem', md: '2.5rem' },
            }}
          >
            Why Choose Us
          </Typography>
          <Grid container spacing={{ xs: 3, md: 6 }} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/gpu.jpg"
                alt="AI Technology"
                sx={{ width: '100%', borderRadius: 3 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {[
                { title: 'Private & Secure', desc: 'End-to-end secured API calls', icon: <SecurityIcon /> },
                { title: 'High Throughput', desc: 'Fast, reliable inference', icon: <ModelsIcon /> },
                { title: 'Cost Effective', desc: 'Competitive pricing', icon: <LowCostIcon /> }
              ].map((item, index) => (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4, p: 2 }}>
                  <Box sx={{ color: '#52b788', mb: 2, fontSize: { xs: 24, md: 30 } }}>{item.icon}</Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      color: '#52b788', 
                      fontSize: { xs: '1.1rem', md: '1.25rem' }
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'rgba(255, 255, 255, 0.8)',
                      fontSize: { xs: '0.9rem', md: '1rem' },
                      textAlign: 'center'
                    }}
                  >
                    {item.desc}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Call to Action */}
      <Box sx={{ py: { xs: 6, md: 12 }, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
          <Typography 
            variant="h4" 
            sx={{ 
              color: '#52b788', 
              fontWeight: 800,
              fontSize: { xs: '1.5rem', md: '2rem' },
              mb: 2
            }}
          >
            Ready to Get Started?
          </Typography>
          <Typography 
            variant="h6" 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.8)', 
              mb: { xs: 4, md: 6 },
              fontSize: { xs: '1rem', md: '1.25rem' },
            }}
          >
            Join thousands of developers
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/signup"
            sx={{
              bgcolor: '#52b788',
              px: { xs: 4, md: 6 },
              py: { xs: 1.5, md: 2 },
              fontSize: { xs: '1rem', md: '1.25rem' },
              borderRadius: 10,
              '&:hover': { bgcolor: '#40916c' }
            }}
          >
            Start Building Now
          </Button>
        </Container>
      </Box>

      {/* Quick Links Section */}
      <Box sx={{ py: { xs: 6, md: 12 }, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={{ xs: 2, md: 4 }} justifyContent="center">
            {[
              { title: 'Documentation', description: 'Comprehensive guides', icon: <DocsIcon />, link: 'https://docs.brilliantai.co' },
              { title: 'Example Apps', description: 'Sample applications', icon: <ModelsIcon />, link: 'https://docs.brilliantai.co/examples/chatbot' }
            ].map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box 
                  onClick={() => window.location.href = item.link}
                  sx={{ 
                    p: { xs: 3, md: 4 },
                    bgcolor: 'rgba(30, 41, 59, 0.8)',
                    borderRadius: 3,
                    textAlign: 'center',
                  }}
                >
                  <Box sx={{ color: '#52b788', mb: 2, fontSize: { xs: 24, md: 30 } }}>{item.icon}</Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      color: 'white', 
                      mb: 1,
                      fontSize: { xs: '1.1rem', md: '1.25rem' }
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'rgba(255, 255, 255, 0.8)',
                      fontSize: { xs: '0.9rem', md: '1rem' }
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Footer */}
      <Box sx={{ bgcolor: '#1e293b', color: 'white', py: { xs: 6, md: 8 } }}>
        <Container maxWidth="lg">
          <Grid container spacing={{ xs: 3, md: 4 }}>
            <Grid item xs={12} md={4}>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: '#52b788', 
                  fontWeight: 700,
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  mb: 2
                }}
              >
                Brilliant AI Technologies LTD
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                <LocationOnIcon sx={{ mr: 1, color: '#52b788', fontSize: { xs: 20, md: 24 } }} />
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.8)',
                    fontSize: { xs: '0.9rem', md: '1rem' }
                  }}
                >
                  5 Brayford Square<br />London, England<br />E1 0SG
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon sx={{ mr: 1, color: '#52b788', fontSize: { xs: 20, md: 24 } }} />
                <Link 
                  href="mailto:hello@brilliantai.co" 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.8)', 
                    fontSize: { xs: '0.9rem', md: '1rem' },
                    textDecoration: 'none'
                  }}
                >
                  hello@brilliantai.co
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: '#52b788', 
                  fontWeight: 700,
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  mb: 2
                }}
              >
                Quick Links
              </Typography>
              {[
                { text: 'About Us', link: 'https://brilliantai.co' },
                { text: 'Documentation', link: 'https://docs.brilliantai.co' },
                { text: 'API Reference', link: 'https://docs.brilliantai.co/api/chat' },
                { text: 'Pricing', link: '/pricing' },
                { text: 'Contact Us', link: '/contact' }
              ].map((item, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Link 
                    href={item.link} 
                    sx={{ 
                      color: 'rgba(255, 255, 255, 0.8)', 
                      fontSize: { xs: '0.9rem', md: '1rem' },
                      textDecoration: 'none'
                    }}
                  >
                    {item.text}
                  </Link>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: '#52b788', 
                  fontWeight: 700,
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  mb: 2
                }}
              >
                Connect With Us
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Link href="https://x.com/brilliantai_hq" target="_blank" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  <TwitterIcon sx={{ fontSize: { xs: 24, md: 30 } }} />
                </Link>
                <Link href="https://www.linkedin.com/company/brilliantai-hq/" target="_blank" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  <LinkedInIcon sx={{ fontSize: { xs: 24, md: 30 } }} />
                </Link>
                <Link href="https://www.facebook.com/profile.php?id=61564797920098" target="_blank" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  <FacebookIcon sx={{ fontSize: { xs: 24, md: 30 } }} />
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Typography 
            variant="body2" 
            align="center" 
            sx={{ 
              mt: { xs: 4, md: 6 }, 
              color: 'rgba(255, 255, 255, 0.6)',
              fontSize: { xs: '0.8rem', md: '0.9rem' }
            }}
          >
            {new Date().getFullYear()} Brilliant AI Technologies LTD. All rights reserved.
          </Typography>
          <Typography 
            variant="body2" 
            align="center" 
            sx={{ 
              mt: 1, 
              color: 'rgba(255, 255, 255, 0.6)',
              fontSize: { xs: '0.8rem', md: '0.9rem' }
            }}
          >
            Built with ❤️ by Brilliant AI
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default Home;